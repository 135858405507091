import React, { useState, useEffect } from 'react';
import {
  Button,
} from 'antd';
import SelectMnemonic from 'components/SelectMnemonic';
import classes from 'classnames';
import Modal from 'react-modal';
import removeIcon from '../../assets/close.svg';
import { modalMatchLithology } from '../../utils';
import { rocks } from '../../utils/constants';

const rockImages = require.context('../../images/patterns', true, /\.png$/);
const rockImagesPatterns = rockImages.keys()
  .reduce((images, path) => {
    images[path.replace(".png", '').replace(/[./]/g, '')] = rockImages(path)
    return images
  }, {})

const Title = ({ title, className, classTitle }) => (
  <h3 className={classes(
    'title-row',
    {
      [className]: !!className,
      [classTitle]: !!classTitle
    }
  )}
  >
    {title}
  </h3>
);

Title.defaultProps = {
  classTitle: 'modal-scale__title'
};

const LithologyMatchCurves = ({
  onAfterSubmit,
  isOpen,
  onCloseModal,

  fileRocksNames,
  fielRocksKeys,

  aiInterpretedLithologyNames,
  aiIntrepretedLithologyKeys,
}) => {
  const [interpretedCurves, setInterpretedCurve] = useState({});
  const [filteredOptions, setFilteredOptions] = useState([]);

  const filterOptions = (options, filterProp, include) => {
    if (!include) {
      return options.filter((o) => !filterProp.includes(o))
    }
    return options.filter((o) => filterProp.includes(o));
  }

  const handleSelectCurve = (curveKey) => (value) => {
    setInterpretedCurve((state) => ({
      ...state,
      [curveKey]: value,
    }));
  };

  useEffect(() => {
    if (interpretedCurves ) {
      const filteredRocks = filterOptions(rocks, Object.values(interpretedCurves), false);
      setFilteredOptions(filteredRocks)
    }
  }, [interpretedCurves]);

  const onSubmit = () => {
    console.log('onSubmit ==>', interpretedCurves);
    onAfterSubmit(interpretedCurves);
    onCloseModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{
        ...modalMatchLithology,
        overflowX: 'hidden',
      }}
      onRequestClose={onCloseModal}
    >
      <div data-cy='match-litho-modal' style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
        <img
          onClick={onCloseModal}
          src={removeIcon}
          className="modal-scale__close"
          data-cy="close-modal"
          alt="close modal"
        />
      </div>
      <div>
        <h2 style={{
          color: '#fff', fontSize: 20, display: 'flex', width: '100%',
          justifyContent: 'center',
          fontWeight: 'bold',
        }}>Match Lithology</h2>
      </div>
      <div
        className="modal-scale__form"
        style={{ paddingLeft: 20, paddingRight: 20, }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          {/* <div>
            <div>
              File
            </div>
            <div style={{ width: 290 }}>
              {(aiInterpretedLithologyNames || []).map((field, index) => {
                return (
                  <>
                    <SelectMnemonic
                      disableLabel
                      key={index}
                      selectWidth={240}
                      value={interpretedCurves[index]}
                      className={field}
                      onChange={handleSelectCurve(index)}
                      label={field.replaceAll('_', ' ').toUpperCase()}
                      options={fielRocksKeys.map((option) => ({
                        value: option,
                        label: option.replaceAll('_', ' ').toUpperCase()
                      }))}
                      id={field}
                    />
                  </>
                );
              })}
            </div>
          </div> */}
          <div>
            {/* <div>
              AI Lithology Prediction
            </div> */}
            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
              {(aiInterpretedLithologyNames || []).map((field, index) => {
                return (
                  <div id={field.value}>
                    <div>
                      <label htlmFor={field.value}>{field.replaceAll('_', ' ').toUpperCase()}</label>
                    </div>
                    <SelectMnemonic
                      disableLabel
                      disabled
                      key={index}
                      selectWidth={240}
                      className={`${field} wrapper-select-well`}
                      onChange={handleSelectCurve(field)}
                      value={interpretedCurves[field]}
                      label={field.replaceAll('_', ' ').toUpperCase()}
                      imgsIcon={rockImagesPatterns}
                      options={filteredOptions.map((rockName) => ({
                        value: rockName,
                        label: rockName
                      }))}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {/* {isProcessing && (
            <p>Processing, creating and saving the well...</p>
          )}
          {isProcessing && (
            <div className="charts-container__spinner">
              <Spinner />
            </div>
          )} */}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 90 }}>
          <Button style={{ background: '#333', color: '#fff', width: 120, height: 40 }}>Cancel</Button>
          <Button type="primary" data-cy='save-litho' onClick={onSubmit} style={{ width: 120, height: 40 }}>Save</Button>
        </div>
      </div>
    </Modal>
  );
};

export default LithologyMatchCurves;
