import React, { useEffect } from 'react';
import { fetchModel } from 'api';
import { Modal, Icon } from 'antd';
import './style.scss';

const LithologyErrorModal = ({ isOpen, handleOk, handleCancel }) => {
    return (
        <Modal
            title={
                <span style={{ color: 'white' }}>
                    <Icon 
                        style={{ color: 'red', fontSize: '1.3rem', marginRight: '0.6rem' }} 
                        type="close-circle" 
                    />
                    Error
                </span>}
            visible={isOpen}
            okText="Upload new data"
            onOk={handleOk}
            cancelText="Cancel"
            onCancel={handleCancel}
            id="lithology-error-modal"
            className="error-modal"
            maskClosable={true}
            okButtonProps={{ style: { background: '#1E97C2', color: '#1D1C22' }, id:'upload-new-data-btn' }}
            cancelButtonProps={{ style: { background: '#292929', color: '#ffffff', border: 'none' }, id: 'cancel-button' }}
        >
            <p className="error-modal-content">Lithology data depth does not match current well depth</p>
        </Modal>
    )
}

export default LithologyErrorModal;