import React, {
  memo,
  useEffect,
  useState
} from 'react';

import { useSelector } from 'react-redux';
import store from 'store';
import {
  nFormatter,
  toggleC1NormalizedChart,
  toggleTotalCarbonChart,
  toggleTotalGasChart
} from 'utils';
import { DEFAULT_SCALES } from 'utils/constants';

const updatedHeader = {
  scales: store.get('configScales') || { ...DEFAULT_SCALES },
  gasTotal: 0,
  totalCarbon: 0,
  c1Normalized: 0
};

const initialHeader = {
  gasTotal: 0,
  totalCarbon: 0,
  c1Normalized: 0
};

const ChartHeader = ({
  rangeValues,
  positionsLeft
}) => {
  const wells = useSelector(state => state.wells);
  // --- Intital State
  const [headerState, setHeaderState] = useState({
    ...initialHeader,
    scales: store.get('configScales') || { ...DEFAULT_SCALES }
  });

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    totalGas: true,
    totalCarbon: true,
    c1Normalized: true
  });

  const tooggleCallbacks = {
    totalGas: toggleTotalGasChart,
    totalCarbon: toggleTotalCarbonChart,
    c1Normalized: toggleC1NormalizedChart
  };

  const toggleTrackCurrentTrack = (event) => {
    const { dataset: { name, index } } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  const dispatchModal = (typeOfTitle) => (event) => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  // TODO create a custom hook to treat it
  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const { total_gas, c1Normalized, totalCarbon } = wells.currentWell;

      const index = wells.selectedDepthIndex || 0;
      const gasTotalValue = total_gas && total_gas[index] ? total_gas[index] : 0;
      const totalCarbonValue = totalCarbon && totalCarbon[index] && totalCarbon[index].length > 1 ? totalCarbon[index][1] : 0;
      const c1NormalizedValue = c1Normalized && c1Normalized[index] ? c1Normalized[index] : 0;
      setHeaderState({
        ...headerState,
        gasTotal: gasTotalValue < 0 ? '' : gasTotalValue.toFixed(2),
        totalCarbon: totalCarbonValue < 0 ? '' : totalCarbonValue.toFixed(2),
        c1Normalized: c1NormalizedValue < 0 ? '' : c1NormalizedValue.toFixed(2)
      });
    }

    if (!wells.currentWell) {
      setHeaderState({
        ...headerState,
        gasTotal: 0,
        totalCarbon: 0,
        c1Normalized: 0
      });
    }
  }, [wells]);

  useEffect(() => {
    // Scales listener
    const configListener = ({ detail }) => {
      updatedHeader.scales.totalGas = detail;
      headerState.scales.totalGas = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener('totalGas', configListener);

    const c1NormalizedConfigListener = ({ detail }) => {
      updatedHeader.scales.c1Normalized = detail;
      headerState.scales.c1Normalized = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener('c1Normalized', c1NormalizedConfigListener);

    const carbonConfigListener = ({ detail }) => {
      updatedHeader.scales.totalCarbon = detail;
      headerState.scales.totalCarbon = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener('totalCarbon', carbonConfigListener);

    // ---- Remove listeners
    return () => {
      document.removeEventListener('totalGas', configListener);
      document.removeEventListener('c1Normalized', c1NormalizedConfigListener);
      document.removeEventListener('totalCarbon', carbonConfigListener);
    };
  }, []);

  const { gasTotal, totalCarbon, c1Normalized, scales } = headerState;

  return (
    <div
      style={{
        display: 'flex',
        height: '133px',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position:'relative',
        width: 220
      }}
    >
      <div
        className="total-gas-header"
        data-name="title"
        data-cmd="openConfig"
        style={{
          height: 40,
          'justifyContent': 'flex-end',
          color: scales.totalGas.color,
          width: 220,
        }}
        onClick={dispatchModal('totalGas')}
        activeCurveConfig="totalGas"
        data-cy="open-config-total-gas"
      >
        <div
          className="total-gas-header__title"
          data-name="title"
          data-cmd="openConfig"
          style={{ left: 80 }}
        >
          {gasTotal ? `Total Gas ${gasTotal}` : 'Total Gas (units)'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox" style={{ marginLeft: 90, }}>
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.totalGas}
            data-name="totalGas"
            data-index="1"
            data-cy="checkbox-total-gas"
          />
          <span className="checkmark" style={{backgroundColor: scales.totalGas.color}}/>
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: scales.totalGas.color
          }}
        >
          <span data-cy="scales-totalGas-min">{nFormatter(scales.totalGas.min)}</span>
          <span data-cy="scales-totalGas-max">{nFormatter(scales.totalGas.max)}</span>
        </div>
      </div>
      <div
        className="total-gas-header"
        data-name="title"
        data-cmd="openConfig"
        style={{
          height: 40,
          justifyContent: 'flex-end',
          color: scales.totalCarbon.color,
          width: 220,
        }}
        onClick={dispatchModal('totalCarbon')}
        activeCurveConfig="totalCarbon"
        data-cy="open-config-total-carbon"
      >
        <div
          className="total-gas-header__title"
          data-name="title"
          data-cmd="openConfig"
          style={{
            color: scales.totalCarbon.color,
            width: 254,
            left: -10
          }}
        >
          {totalCarbon ? `Total Carbon ${totalCarbon}` : 'Total Carbon'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox" style={{ marginLeft: 90, }}>
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.totalCarbon}
            data-name="totalCarbon"
            data-index="2"
            data-cy="checkbox-total-carbon"
          />
          <span className="checkmark" style={{ backgroundColor: scales.totalCarbon.color }}/>
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: scales.totalCarbon.color
          }}
        >
          <span data-cy="scales-total-carbon-min">{nFormatter(scales.totalCarbon.min)}</span>
          <span data-cy="scales-total-carbon-max">{nFormatter(scales.totalCarbon.max)}</span>
        </div>
      </div>
      <div className="total-gas-labels" style={{ position: 'relative', borderBottom: '1px solid #000' }}>
        {
          rangeValues.map((label, index) => (
            <span
              className={`total-gas-label-${index}`}
              index={label.toString()}
              style={{
                left: positionsLeft[index]
              }}
            >
              {label ? label.replace('<tspan>', '').replace('</tspan>', '') : null}
            </span>
          ))
        }
      </div>
    </div>
  );
};

export default memo(ChartHeader);
