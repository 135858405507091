/* eslint-disable */
import React from 'react';

import { notification } from 'antd';
import Highcharts from 'highcharts';
import {
  del as delFromIndexedDB,
  get as getFromIndexDB,
  set as updateOnIndexDB
} from 'idb-keyval';
import _get from 'lodash.get';
import keys from 'lodash.keys';
import store from 'store';

import { calculateEthene } from './CalculationsEtheneAndEthane';
import { ABBREVS_ROCKS_NAMES } from './constants';
import ZoomQueue from './Queue';

/**
  * Highlight a point by showing tooltip, setting hover state and draw crosshair
  * -Show the hover marker
  * -Show the tooltip
  * -Show the crosshair
  */
function highlightCrossHair(event) {
  const newEvent = this.series.chart.pointer.normalize(event);
  this.series.chart.xAxis[0].drawCrosshair(newEvent, this);
}

Highcharts.Point.prototype.highlight = highlightCrossHair;

export const syncronizeTooltipWithHG = (HighchartsInstance) => (elementId) => {
  document.querySelector(elementId).addEventListener('mousemove', (e) => {
    let chart = null;
    let point = null;
    let event = null;

    const defaultAxis = { x: 0, y: 0 };
    const values = {
      0: defaultAxis,
      1: defaultAxis,
      2: defaultAxis,
      3: defaultAxis,
      4: defaultAxis,
      5: defaultAxis,
      6: defaultAxis,
      7: defaultAxis,
      8: defaultAxis,
      9: defaultAxis,
      10: defaultAxis,
      11: defaultAxis
    };

    // Exclude the first chart
    for (let i = 0; i < HighchartsInstance.charts.length; i++) {
      chart = HighchartsInstance.charts[i];
      if (chart && chart.pointer) {
        event = chart.pointer.normalize(e); // Find coordinates within the chart

        // TODO treat when exist more than one series in chart
        point = chart.series[0] && chart.series[0].searchPoint(event, true); // Get the hovered point
        if (!point && chart.series[1]) {
          chart.series[1].searchPoint(event, true);
        }

        // // TODO refactor it
        if (chart.series.length > 1) {
          const allTracks = chart.series.reduce((acc, serie, index) => {
            const tmpPoint = serie.searchPoint(event, true);
            if (tmpPoint) {
              acc[index] = { x: tmpPoint.x, y: tmpPoint.y };
            }
            return acc;
          }, {});
          values[i] = allTracks;
        } else if (chart.series.length === 1 && point) {
          values[i] = { x: point.x, y: point.y };
        }

        // highlight the red line
        if (point && point.highlight) {
          point.highlight(e);
        }
      }
    }
    const allValues = new CustomEvent('tooltip-values', {
      detail: { tooltipValues: values, chart }
    });
    document.dispatchEvent(allValues);
  });

  // TODO create a hook to remove this listener
  document.querySelector('.charts-container').addEventListener('mouseout', (event) => {
    // const resetHeaderTitle = new CustomEvent('reset-header', {
    //   detail: 0,
    // });
    // document.dispatchEvent(resetHeaderTitle);
  }, false);
};

export const syncronizeGeopressionTooltipWithHG = (HighchartsInstance) => (elementId) => {
  document.querySelector(elementId).addEventListener('mousemove', (e) => {
    let chart = null;
    let point = null;
    let event = null;

    const defaultAxis = { x: 0, y: 0 };
    const values = {
      0: defaultAxis,
      1: defaultAxis,
      2: defaultAxis,
      3: defaultAxis,
      4: defaultAxis,
      5: defaultAxis,
      6: defaultAxis,
      7: defaultAxis,
      8: defaultAxis,
      9: defaultAxis,
      10: defaultAxis,
      11: defaultAxis
    };

    // Exclude the first chart
    for (let i = 0; i < HighchartsInstance.charts.length; i++) {
      chart = HighchartsInstance.charts[i];
      const chartId = _get(chart, 'renderTo.id', false);

      if ((chartId === 'dxc' || chartId === 'gradient-chart') && chart && chart.pointer) {
        event = chart.pointer.normalize(e); // Find coordinates within the chart

        // TODO treat when exist more than one series in chart
        point = chart.series[1].searchPoint(event, chart); // Get the hovered point

        // highlight the red line
        if (point && point.highlight) {
          point.highlight(e);
        }
      }
    }

    const allValues = new CustomEvent('tooltip-values', {
      detail: { tooltipValues: values, chart }
    });
    document.dispatchEvent(allValues);
  });

  // TODO create a hook to remove this listener
  document.querySelector('.charts-container').addEventListener('mouseout', (event) => {
    // const resetHeaderTitle = new CustomEvent('reset-header', {
    //   detail: 0,
    // });
    // document.dispatchEvent(resetHeaderTitle);
  }, false);
};

export const syncronizeTooltip = syncronizeTooltipWithHG(Highcharts);
export const syncronizeGeopressionTooltip = syncronizeGeopressionTooltipWithHG(Highcharts);

/**
 *
  * fn: handleSelectArea
  * Description: this function will create a selected area
  *
  */
export const handleSelectArea = (
  chart, afterSelect, selectedId, zIndex = 20,
  selectColor = 'rgba(51, 92, 173, 0.25)'
) => event => {
  event.preventDefault();
  const { min, max } = event.xAxis[0];
  const xAxis = chart.xAxis[0];

  const selectionId = `selection${selectedId}`;
  xAxis.removePlotBand(selectionId);

  const plotSelection = {
    from: min,
    to: max,
    color: selectColor,
    id: selectionId,
    zIndex
  };

  xAxis.addPlotBand(plotSelection);
  if (typeof afterSelect === 'function') {
    afterSelect(min, max, event, selectionId);
  }
};

export const getToolTipValues = (eventId) => function () {
  const series = [];
  const { points } = this;

  for (const prop in points) {
    series.push({
      value: points[prop].y,
      serie: points[prop].series.name
    });
  }

  let nVisible = 0;
  if (this.series && this.series.chart) {
    for (let i = 0; i < this.series.chart.series.length; i++) {
      if (this.series.chart.series[i].visible) {
        nVisible++;
      }
    }

    const crossHairs = this.series.chart.tooltip.crosshairs[0];
    if (crossHairs) {
      if (nVisible > 1) {
        crossHairs.attr({
          stroke: '#FA8072',
          width: 1
        });
      } else {
        crossHairs.attr({
          stroke: '#FA8072',
          width: 1
        });
      }
    }
  }

  const currentValue = new CustomEvent(eventId, {
    detail: {
      value: this.y,
      x: this.x,
      series
    }
  });

  document.dispatchEvent(currentValue);

  return '';
};

export const chartsToApplyZoom = [
  'selected-depth',
  'gamma_ray',
  'mlporosity',
  'lithology',
  'chromatography',
  'gas-composition',
  'balance-ratio',
  'reason-chart',
  'isotope-s13',
  'lithology-scores',
  'total-gas',
  'real-time-lithology',
  'litho-scores'
];

export const displayZoomBtn = (valueVisible = true, valueGeopressure = false) => {
  const zoomInButton = new CustomEvent('zoom-in', {
    detail: {
      visible: valueVisible,
      geopressure: valueGeopressure
    }
  });
  document.dispatchEvent(zoomInButton);
};

export const disableDefaultZoom = {
  resetZoomButton: {
    theme: {
      display: 'none'
    }
  }
};

export const applyResetZoomWithHg = (HigchartsInstance, chartIds) => (newMin, newMax, newMinDepth, newMaxDepth) => {
  for (let i = 0; i < HigchartsInstance.charts.length; i++) {
    let chart = null;
    chart = HigchartsInstance.charts[i];
    const chartId = _get(chart, 'renderTo.id', false);
    const shouldApplyZoom = checkIfShouldApplyZoom(
      chart, chartIds, chartId
    );
    if (shouldApplyZoom && chart && chart.xAxis[0].setExtremes &&
      (chartId === 'lithology' || chartId === 'real-time-lithology')
    ) {
      chart.xAxis[0].setExtremes(newMinDepth, newMaxDepth, false, false);
      chart.redraw();
    } else if (
      chartId !== 'dxc' && chartId !== 'gradient-chart' &&
      shouldApplyZoom && chart && chart.xAxis[0].setExtremes
    ) {
      chart.xAxis[0].setExtremes(newMin, newMax, false, false);
      chart.redraw();
    }
  }
};

export const applyResetZoomGeopressionWithHg = (HighchartsInstance) => (newMin, newMax) => {
  let chart = null;
  let chartId = null;

  for (let i = 0; i < HighchartsInstance.charts.length; i++) {
    chart = HighchartsInstance.charts[i];
    chartId = _get(chart, 'renderTo.id', false);

    if (chartId === 'dxc') {
      applyGradientChartZoom(newMin, newMax);
      chart.xAxis[0].setExtremes(newMin, newMax, false, false);
      chart.redraw();
    }
  }
};

export const applyResetZoom = applyResetZoomWithHg(Highcharts, chartsToApplyZoom);
export const applyResetZoomGeopression = applyResetZoomGeopressionWithHg(Highcharts, chartsToApplyZoom);

/**
 * fn: checkIfShouldApplyZoom 
 * @param {object} chart - is the instance the current chart  
 * @param {array} chartsToApplyZoom - a list with the chartId
 * @return {boolean} should returns true of false
 */
export const checkIfShouldApplyZoom = (
  chart, chartsToApplyZoom, chartId
) => {
  return _get(chart, 'xAxis[0].setExtremes', false)
    && chartsToApplyZoom.includes(chartId);
};

export const checkAndApplyZoom = (
  HighchartsInstance, chartsIds
) => (min, max, minDepth, maxDepth) => {
  let i = 0;
  let chart = null;
  const len = HighchartsInstance.charts.length;

  for (i; i < len; i++) {
    chart = HighchartsInstance.charts[i];
    const chartId = _get(chart, 'renderTo.id', false);
    // const shouldApplyZoom = checkIfShouldApplyZoom(
    //   chart, chartsIds, chartId
    // );
    // if ((chartId === 'lithology' || chartId === 'real-time-lithology' || chartId === 'litho-scores')  && shouldApplyZoom) {
    //   const xData = chart.xAxis[0].series[0].data;
    //   const minDataByIndex = xData && xData[min] ? xData[min] : { y: 0 };
    //   const maxDataByIndex = xData && xData[max] ? xData[max] : { y: 0 };
    //   chart.xAxis[0].setExtremes(minDataByIndex.y, maxDataByIndex.y, true, true);
    //   chart.redraw();
    // } else 
    if (
      chartId !== 'dxc' && chartId !== 'gradient-chart' &&
      chart && chart.xAxis && chart.xAxis[0]
    ) {
      chart.xAxis[0].setExtremes(min, max, true, true);
      chart.redraw();
    }
  }
};

export const checkAndApplyZoomGeopressure = (
  HighchartsInstance
) => (min, max) => {
  let chart = null;
  let i = 0;
  let chartId = null;
  const len = HighchartsInstance.charts.length;

  for (i; i < len; i++) {
    chart = HighchartsInstance.charts[i];
    chartId = _get(chart, 'renderTo.id', false);
    if (chartId === 'dxc' || chartId === 'gradient-chart') {
      chart.xAxis[0].setExtremes(min, max, true, true);
      chart.redraw();
    }
  }
};

export const applyZoom = checkAndApplyZoom(Highcharts, chartsToApplyZoom);
export const applyZoomGeopressure = checkAndApplyZoomGeopressure(Highcharts, chartsToApplyZoom);

const applyGradientChartZoom = (min, max) => {
  const gradientChartEvent = new CustomEvent('receive-gradient-zoom', {
    detail: { min, max }
  });
  document.dispatchEvent(gradientChartEvent);
};

/**
 * fn: propagateZoom
 * Description: Should apply the zoom and store the min and max values in a queue module
 * @param {function} enqueueItem - method from ZoomQueue module
 * @param {function} applyZoom - callback
 * @param {object} event - is an event object from the chart captured when make a zoom
 * @return {undefined}
 */
export const propagateZoomWithCallbacks = (enqueueItem, applyZoom) => (event, depth) => {
  let { min, max } = event.xAxis[0];

  const { target } = event;
  let minDepth = parseInt(min); // index
  let maxDepth = parseInt(max); // index
  console.log(`propagateZoomWithCallbacks ==>`, { min, max });

  // normalize to get always index
  // if (target.renderTo.id === 'lithology' || target.renderTo.id === 'real-time-lithology' ||  target.renderTo.id === 'litho-scores') {
  //   const tempMinDepth = binarySearch(depth, min);
  //   const tempMaxDepth = binarySearch(depth, max);
  //   if (tempMinDepth && tempMaxDepth) {
  //     minDepth = tempMinDepth;
  //     maxDepth = tempMaxDepth;
  //   }
  // }

  // access depth 
  const minLithoIndex = depth && depth[parseInt(minDepth)] ? depth[parseInt(minDepth)] : 0;
  const maxLithoIndex = depth && depth[parseInt(maxDepth)] ? depth[parseInt(maxDepth)] : 0;

  // Here we are adding the selected values, and on the ZoomButton component
  // after click will remove the last and aplly the oldest value
  enqueueItem({
    min: minDepth,
    max: maxDepth,
    minDepth: minDepth,
    maxDepth: maxDepth
  });
  applyZoom(minDepth, maxDepth, minLithoIndex, maxLithoIndex);
};

export const propagateGeopressureZoomWithCallbacks = (enqueueItem, applyZoomGeopressure) => ({ xAxis, startDepth }) => {
  const { min, max } = xAxis[0];
  const minValue = parseInt(min);
  const maxValue = parseInt(max);
  // Here we are adding the selected values, and on the ZoomButton component
  // after click will remove the last and aplly the oldest value
  enqueueItem({ min: minValue, max: maxValue }); // index in array on highcharts
  applyZoomGeopressure(minValue, maxValue);

  // This code dispatch event to gradient chart to receive and normalize the index by depth
  applyGradientChartZoom(minValue, maxValue);
};

/**
 * fn: propagateZoom
 * Description: A function proxy that just apply two callbacks
 *              and store the reference of propagateZoomWithCallbacks.
 */
export const propagateZoom = propagateZoomWithCallbacks(ZoomQueue.enqueue, applyZoom);
export const propagateGeopressureZoom = propagateGeopressureZoomWithCallbacks(ZoomQueue.enqueue, applyZoomGeopressure);

export const Compare = {
  LESS_THAN: -1,
  BIGGER_THAN: 1,
  EQUALS: 0
};

export function defaultCompare(a, b) {
  if (a.toFixed() === b.toFixed()) {
    return Compare.EQUALS;
  }
  return a < b ? Compare.LESS_THAN : Compare.BIGGER_THAN;
}

export const DOES_NOT_EXIST = -1;

export function binarySearch(array, value, compareFn = defaultCompare) {
  let low = 0;
  let high = array.length - 1;
  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    const element = array[mid];
    if (compareFn(element, value) === Compare.LESS_THAN) {
      low = mid + 1;
    } else if (compareFn(element, value) === Compare.BIGGER_THAN) {
      high = mid - 1;
    } else {
      return mid;
    }
  }
  return DOES_NOT_EXIST;
}

// normalize the indirection of depth with highcharts and the value displayed inside the label
// this is optimized with a binary search to avoid a linear search
export const propagateZoomInGradientChartWithDepthValue = (enqueueItem, applyZoom) =>
  ({ xAxis }, depth) => {
    const { min, max } = xAxis[0];
    const minIndex = binarySearch(depth, min);
    const maxIndex = binarySearch(depth, max);

    // Here we are adding the selected values, and on the ZoomButton component
    // after click will remove the last and aplly the oldest value
    enqueueItem({ min: minIndex, max: maxIndex }); // index in array on highcharts
    applyZoom(minIndex, maxIndex); // index applied in each chart
    applyGradientChartZoom(min, max); // depth value applied on gradient
  };

export const propagateGradientZoom = propagateZoomInGradientChartWithDepthValue(
  ZoomQueue.enqueue,
  applyZoom
);


export const disableDots = {
  allowPointSelect: false,
  marker: {
    enabled: false,
    states: {
      hover: {
        enabled: false
      }
    }
  }
};

export const disableLabels = {
  labels: {
    format: null,
    enabled: false
  }
};

export function rotateElement(targetClass) {
  let R2D;
  let active = false;
  let angle = 0;
  let center = {
    x: 0,
    y: 0
  };
  let init;
  let rotate;
  let rotation = 0;
  let start;
  let startAngle = 0;
  let stop;
  let stopRotation;

  document.ontouchmove = (e) => e.preventDefault();

  init = function () {
    const target = document.querySelector(targetClass);
    target.addEventListener('mousedown', start, false);
    target.addEventListener('mousemove', rotate, false);
    return target.addEventListener('mouseup', stop, false);
  };

  R2D = 180 / Math.PI;

  start = function (e) {
    let height; let left; let top; let width; let x; let y; let _ref;
    e.preventDefault();
    _ref = this.getBoundingClientRect();
    top = _ref.top;
    left = _ref.left;
    height = _ref.height;
    width = _ref.width;
    center = {
      x: left + (width / 2),
      y: top + (height / 2)
    };
    x = e.clientX - center.x;
    y = e.clientY - center.y;
    startAngle = R2D * Math.atan2(y, x);
    return active = true;
  };

  rotate = function (e) {
    let d; let x; let y;
    e.preventDefault();
    x = e.clientX - center.x;
    y = e.clientY - center.y;
    d = R2D * Math.atan2(y, x);
    rotation = d - startAngle;

    if (active) {
      // NOTE: This peace of code will concat the the old translate of the drag
      // with the new rotate value from this rotateElement function
      e.target.dataset.rotate = `${angle + rotation}deg`;
      return this.style.webkitTransform = `rotate(${angle + rotation}deg)`;
    }
  };

  stop = function () {
    angle += rotation;
    return active = false;
  };

  stopRotation = function () {
    const target = document.querySelector(targetClass);
    target.removeEventListener('mousedown', start, false);
    target.removeEventListener('mousemove', rotate, false);
    target.addEventListener('mouseup', stop, false);
  };

  init();

  return { stopRotation };
}

export function startDragAndResize(id, handle) {
  // Access the handle that is the point to resize
  const resizeHandle = document.getElementById(handle);
  // Acccess the box container
  const box = document.getElementById(id);
  // Current element selected
  let sele;

  // Add lister to start the resize
  resizeHandle.addEventListener('mousedown', initialiseResize, false);

  // Add listeners
  function initialiseResize(e) {
    window.addEventListener('mousemove', startResizing, false);
    window.addEventListener('mouseup', stopResizing, false);
  }

  function startResizing({ target, clientX, clientY }) {
    // For element to prevent colision
    if (target.id) {
      sele = box;
    }
    // Prevent to overflow the box
    // TODO get this params from each chart
    if (sele && clientY < 338) {
      sele.style.height = `${clientY - sele.offsetTop}px`;
    }
    if (sele && clientX < 548) {
      // minus 50 is a normalization to avoid every time to resize calculate the increasing right
      sele.style.width = `${clientX - sele.offsetLeft - 46}px`;
    }
  }

  function stopResizing() {
    sele = undefined;
    window.removeEventListener('mousemove', startResizing, false);
    window.removeEventListener('mouseup', stopResizing, false);
  }

  let selected = null; // Object of the element to be moved
  let xPosition = 0; // Stores x & y coordinates of the mouse pointer
  let yPosition = 0;
  let xElement = 0; // Stores top, left values (edge) of the element
  let yElement = 0;

  // Will be called when user starts dragging an element
  function dragInit(element) {
    // Store the object of the element which needs to be moved
    selected = element;
    xElement = xPosition - selected.offsetLeft;
    yElement = yPosition - selected.offsetTop;
  }

  // Will be called when user dragging an element
  function moveElement(e) {
    xPosition = document.all ? window.event.clientX : e.pageX;
    yPosition = document.all ? window.event.clientY : e.pageY;

    // Prevent colision of boxes
    if (selected !== null && !sele) {
      // Prevet to drag out of the max area
      // if the point of click with the size of the element not overflow the max size
      const leftPosition = xPosition - xElement;
      const topPosition = yPosition - yElement;
      const bottomLimit = topPosition + selected.getBoundingClientRect().height;
      if ((yPosition) > 0 && bottomLimit < 344) {
        selected.style.top = `${topPosition}px`;
      }
      if ((xPosition - xElement) > (-6) && (xPosition - xElement) < 500) {
        selected.style.left = `${leftPosition}px`;
      }
    }
  }

  // Destroy the object when we are done
  function stopResize() {
    selected = null;
  }

  // Bind the functions...
  box.onmousedown = function () {
    dragInit(this);
    return false;
  };

  document.onmousemove = moveElement;
  document.onmouseup = stopResize;

  return {
    stopResize,
    stopResizing: () => {
      document.onmousemove = () => { };
      document.onmouseup = () => { };
    }
  };
}

export const replaceCommaToDot = x => parseFloat(x.replace(',', '.'));

export const syncronizeDepthZoomInAllCharts = (chartIds) => (Highcharts, min, max, depth) => {
  for (let i = 0; i < Highcharts.charts.length; i++) {
    const chart = Highcharts.charts[i];
    const chartId = _get(chart, 'renderTo.id', false);

    // if (chartId && (chartId === 'lithology' || chartId === 'real-time-lithology') || chartId === 'litho-scores') {
    //   const newMin = depth && min && depth[parseInt(min)] ? depth[parseInt(min)] : 0;
    //   const newMax =  depth && max && depth[parseInt(max)] ? depth[parseInt(max)] : 0;
    //   console.log(`newMin ==>`, { newMin, newMax, min, max, depth, chart, depthMin: depth[min], depthMax: depth[max] });
    //   chart.xAxis[0].setExtremes(depth[min], depth[max], true, true);
    //   chart.redraw();
    // } else 
    if (
      chartId !== 'dxc' && chartId !== 'gradient-chart' &&
      chart && chart.xAxis && chart.xAxis[0]
    ) {
      chart.xAxis[0].setExtremes(min, max, false, false);
      chart.redraw();
    }
  }
};

export const syncronizeDepthZoom = syncronizeDepthZoomInAllCharts(chartsToApplyZoom);


/**
 * fn: defaultCrossHair
 * @return should return the default params for crossHair.
 */
export const defaultCrossHair = {
  crosshairs: [{
    snap: false,
    width: 1,
    color: '#FA8072',
    zIndex: 101,
    stroke: '#FA8072',
    dashStyle: 'solid'
  },]
};

export const checkGenerateCrossPlots = () => store.get('crossPlots');

export const getUnit = () => store.get('unitOfMeansurement')
  ? store.get('unitOfMeansurement') : 'm';

// TODO create a hook to improve this
export const toggleTrack = (scope) => (index) => {
  scope.setState(state => {
    const shouldDisplay = !scope.state.series[index];
    const currentTrack = scope.chart.series[index];
    if (shouldDisplay) {
      currentTrack.show();
    } else {
      currentTrack.hide();
    }
    return {
      series: { ...state.series, [index]: shouldDisplay }
    };
  });
};

// Distach what track we need toggle
export const toggleTrackGenerator = chartEvent => index => () => {
  const trackEvent = new CustomEvent(
    chartEvent,
    { detail: { index } }
  );
  document.dispatchEvent(trackEvent);
};

export const toggleUncertainty = toggleTrackGenerator('toggle-uncert-estimation');
export const toggleDxc = toggleTrackGenerator('toggle-dxc');
export const toggleGasComposition = toggleTrackGenerator('toggle-gas-composition');
export const toggleSFChart = toggleTrackGenerator('toggle-sf-chart');
export const toggleCromaChart = toggleTrackGenerator('toggle-chroma-chart');
export const toggleWobChart = toggleTrackGenerator('toggle-wob-chart');
export const toggleMseCurve = toggleTrackGenerator('toggle-mse-curve');
export const toggleWobCurve = toggleTrackGenerator('toggle-wob-curve');
export const toggleAiGrCurve = toggleTrackGenerator('toggle-aiGr-curve');
export const toggleGrCurve = toggleTrackGenerator('toggle-gr-curve');
export const toggleMLPorosity = toggleTrackGenerator('toggle-ml-porosity');
export const toggleRopCurve = toggleTrackGenerator('toggle-rop-curve');
export const toggleTotalGasChart = toggleTrackGenerator('toggle-total-gas-chart');
export const toggleTotalCarbonChart = toggleTrackGenerator('toggle-total-carbon-chart');
export const toggleC1NormalizedChart = toggleTrackGenerator('toggle-c1-normalized-chart');
export const toggleLithologyScores = toggleTrackGenerator('toggle-lithology-scores');

const multiplyToKeepSignal = (signalValue, number) => signalValue * number;

// TODO LEO should refactor again
/**
 * fn: nFormatter
 * @param {number} num 
 * @param {number} digits 
 * @return {string} should be return a shorten number based in metric prefix to thousands, 
 * millions, billions, etc.
 * https://en.wikipedia.org/wiki/Metric_prefix
 */
export const nFormatter = (num, digits = 1) => {
  // Map wth all values of the metric system
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'k' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' },
  ];

  let i = 0;
  const absoluteValue = Math.abs(num);
  const signalNumber = Math.sign(num);

  for (i = si.length - 1; i > 0; i--) {
    if (absoluteValue >= si[i].value) {
      break;
    }
  }

  const metricsValue = si[i].value;

  // This toFixed is necessary to keep the broken values that is not zero
  // example: 8.8M should be 8.8M 8.8
  // 8000 / 1000 = 8 the purpose is get the decimal value and get the first algarism
  const formattedValue = (absoluteValue / metricsValue).toFixed(digits);

  // should remove all zero values after the dot (.) 
  const replaceZeroAfterDot = formattedValue.replace(/\.?0+$/, '');

  const result = multiplyToKeepSignal(signalNumber, replaceZeroAfterDot);

  return `${result}${si[i].symbol}`;
};

export const modalStyleWellPhasesModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    background: 'rgb(29, 28, 28)',
    color: '#fff',
    border: 'none',
    width: '680px',
    height: '500px',
    borderRadius: 5
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.65)'
  }
};

export const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    background: 'rgb(29, 28, 28)',
    color: '#fff',
    border: 'none'
  },
  overlay: {
    zIndex: 900,
    backgroundColor: 'rgba(0, 0, 0, 0.65)'
  }
};

export const modalStyleConfirmSettings = {
  content: {
    width: '424px',
    height: 'auto',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '24px',
    background: '#2F2E36',
    color: '#fff',
    border: 'none',
    borderRadius: '4px'
  },
  overlay: {
    zIndex: 900,
    backgroundColor: 'transparent'
  }
};

export const modalStyleCreateWell = {
  content: {
    maxHeight: '92%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '1rem 2.5rem',
    background: 'rgb(29, 28, 28)',
    color: '#fff',
    border: 'none'
  },
  overlay: {
    zIndex: 900,
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
    overflow: 'scroll',
    alignItems: 'center'
  }
};

export const modalStyleSelectInWell = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    background: 'rgb(29, 28, 28)',
    color: '#fff',
    border: 'none',
    width: '875px',
    height: '500px',
    borderRadius: 10
  },
  overlay: {
    zIndex: 900,
    backgroundColor: 'rgba(0, 0, 0, 0.65)'
  }
};

export const modalStyleDataSource = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    background: 'rgb(29, 28, 28)',
    color: '#fff',
    border: 'none',
    minWidth: '845px',
    minHeight: '580px',
    maxWidth: '1100px',
    maxHeight: '712px',
    borderRadius: 10,
  },
  overlay: {
    zIndex: 900,
    backgroundColor: 'rgba(0, 0, 0, 0.65)'
  }
};


export const modalMatchLithology = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    background: 'rgb(29, 28, 28)',
    color: '#fff',
    border: 'none',
    width: '680px',
    height: '650px',
    borderRadius: 10
  },
  overlay: {
    zIndex: 900,
    backgroundColor: 'rgba(0, 0, 0, 0.65)'
  }
};

export const modalStyleInWell = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    background: 'rgb(29, 28, 28)',
    color: '#fff',
    border: 'none',
    width: '950px',
    height: '690px',
    borderRadius: 10
  },
  overlay: {
    zIndex: 900,
    backgroundColor: 'rgba(0, 0, 0, 0.65)'
  }
};

export const modalStyleMachineLearning = {
  content: {
    width: 1100,
    height: 600,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    background: '#202526', // rgb(29, 28, 28)
    color: '#fff',
    border: 'none',
  },
  overlay: {
    zIndex: 900,
    backgroundColor: 'rgba(0, 0, 0, 0.65)'
  }
};

export const modalStyleEditLithology = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    background: 'rgb(29, 28, 28)',
    color: '#fff',
    border: 'none',
    minWidth: '795px',
    minHeight: '380px',
    maxWidth: '1100px',
    maxHeight: '712px',
    borderRadius: 10,
  },
  overlay: {
    zIndex: 900,
    backgroundColor: 'rgba(0, 0, 0, 0.65)'
  }
};

export const defaultCrossPlotScales = {
  balanceRatioToggle: {
    chart1: {
      y: { min: 5900, max: 6300, title: 'MD Depth', yAxisType: 'linear' },
      x: { min: 0, max: 1000, title: 'Fluid Typing', xAxisType: 'linear' },
      isVisible: true,
      data: []
    },
    chart2: {
      y: { min: 0.0001, max: 100, title: '(C4+C5) / (C1+C2)', yAxisType: 'logarithmic' },
      x: { min: 0.1, max: 1000, title: '(C1+C2) / C3', xAxisType: 'logarithmic' },
      isVisible: true,
      data: []
    },
    chart3: {
      y: { min: 0, max: 100, title: 'WH - Weteness', yAxisType: 'linear' },
      x: { min: 0.1, max: 1000, title: 'BH - Balance', xAxisType: 'logarithmic' },
      isVisible: true,
      data: []
    },
    chart4: {
      y: { min: 1, max: 1000, title: 'C1/C3', yAxisType: 'logarithmic' },
      x: { min: 0, max: 90, title: 'C1', xAxisType: 'linear' },
      isVisible: true,
      data: []
    },
    chart5: {
      y: { min: 0.1, max: 100000, title: 'C1/C3', yAxisType: 'logarithmic' },
      x: { min: 0.1, max: 1000, title: '(C1 / C4 + C5)', xAxisType: 'logarithmic' },
      isVisible: true,
      data: []
    }
  },
  C1C2SF: {
    chart1: {
      y: { min: 1000, max: 7000, title: 'MD Depth (m)', yAxisType: 'linear' },
      x: { min: 0, max: 20, title: 'C1/C2', xAxisType: 'linear' },
      isVisible: true
    },
    chart2: {
      y: { min: 1000, max: 7000, title: 'MD Depth (m)', yAxisType: 'linear' },
      x: { min: 0, max: 4, title: 'SF C2-5 (ppm)', xAxisType: 'linear' },
      isVisible: true
    },
    chart3: {
      y: { min: 0.5, max: 5, title: 'SF C2-5 (ppm)', yAxisType: 'linear' },
      x: { min: 0, max: 40, title: 'C1/C2', xAxisType: 'linear' },
      isVisible: true
    },
    chart7: {
      y: { min: 1, max: 100, title: 'C1/C2', yAxisType: 'linear' },
      x: { min: 0, max: 9, title: 'C2/C3', xAxisType: 'linear' },
      data: []
    },
    chart8: {
      y: { min: 1, max: 10, title: 'C2/C3', yAxisType: 'linear' },
      x: { min: 0, max: 80, title: 'C2/iC4', xAxisType: 'linear' },
      data: []
    },
    chart9: {
      y: { min: 1000, max: 7000, title: 'MD Depth (m)', yAxisType: 'linear' },
      x: { min: 0, max: 5, title: 'iC5/nC5', xAxisType: 'linear' },
      data: []
    },
    chart10: {
      y: { min: 1000, max: 7000, title: 'MD Depth (m)', yAxisType: 'linear' },
      x: { min: 0, max: 5, title: 'iC4/nC4', xAxisType: 'linear' },
      data: []
    }
  }
};

// TODO create unit tests to this code
/**
 * fn: calculateAPIC2C3
 * @param {number} c2 - c2 gas value in PPM.
 * @param {number} c3 - c3 gas value in PPM.
 * @return {number} result - should return a C2 and C3 ratio. 
 */
export const calculateAPIC2C3 = (C2, C3) => {
  // Treating Zero
  const C2ByC3 = isNaN(C2 / C3) || C3 === 0 ? 0 : (C2 / C3);
  let result = 22.093 * (C2ByC3) - 1.5918;
  if (result < 5) {
    result = 5; // When the value is lesst than 5 display a interrogation icon 
  }
  return Math.round(result);
};

/**
 * fn: calculateAPIGravityC2C34
 * @param {number} C2 - c2 gas value in PPM.
 * @param {number} C3 - c3 gas value in PPM.
 * @param {number} C4 - c4 gas value in PPM.
 * @return {number} result - should return return the density between C2, C3 and C4
 * */
export const calculateAPIGravityC2C34 = (C2, C3, C4) => {
  const C3C4 = C3 + C4;
  const C2ByC3C4 = isNaN(C2 / (C3 + C4)) || C3C4 === 0 ? 0 : (C2 / C3C4);
  let result = 31.426 * C2ByC3C4 + 3.466;
  if (result < 5) {
    result = 5; // Display alert here 
  }
  return Math.round(result);
};

/**
 * fn: calculateGORC2C34
 * @param {number} c2 - c2 gas value in PPM.
 * @param {number} c3 - c3 gas value in PPM.
 * @param {number} c4 - c4 gas value in PPM.
 * @return {number} result - should return a C2, C3 and C4 ratio. 
 */
export const calculateGORC2C34 = (C2, C3, C4) => {
  // TODO se GUAR tive rum valor muito baixo jogar um alerta, um ícone de exclamação
  const C3C4 = C3 + C4;
  const C2ByC3C4 = isNaN(C2 / (C3 + C4)) || C3C4 === 0 ? 0 : (C2 / C3C4);
  let result = 2383.3 * C2ByC3C4 - 1996.8;
  if (result < 100) {
    result = 100;
  }
  return Math.round(result);
};

/**
 * fn: calculateGORC2C3
 * @param {number} c2 - c2 gas value in PPM.
 * @param {number} c3 - c3 gas value in PPM.
 * @return {number} result - should return a C2 and C3 ratio. 
 */
export const calculateGORC2C3 = (C2, C3) => {
  const C2ByC3 = isNaN(C2 / C3) || C3 === 0 ? 0 : (C2 / C3);
  let result = 1573.1 * C2ByC3 - 2193.1;
  if (result < 100) {
    result = 100;
  }
  return Math.round(result);
};

/**
 * fn: calculateGeochemistryChart3
 * @param {number} c1 - c1 gas value in PPM.
 * @param {number} c2 - c2 gas value in PPM.
 * @param {number} c3 - c3 gas value in PPM.
 * @param {number} ic4 - ic4 gas value in PPM.
 * @param {number} ic5 - ic5 gas value in PPM.
 * @param {number} nc4 - nc4 gas value in PPM.
 * @param {number} nc5 - nc5 gas value in PPM.
 * @return {array} result - should return a array with x and y. 
 */
export const calculateGeochemistryChart3 = (c1, c2, c3, ic4, ic5, nc4, nc5) => {
  const c4 = ic4 + nc4;
  const c5 = ic5 + nc5;
  const c1ByC2 = c1 / c2;
  const c3ByC1 = c3 / c1;
  const c4ByC5 = c4 / c5;
  const resultSF = (c1ByC2 + c3ByC1 + c4ByC5) / 3;
  const x = c1 / c2;
  const y = resultSF;
  return [x, y];
};

/**
 * fn: calculateGeochemistryChart2
 * @param {number} c1 - c1 gas value in PPM.
 * @param {number} c2 - c2 gas value in PPM.
 * @param {number} c3 - c3 gas value in PPM.
 * @param {number} ic4 - ic4 gas value in PPM.
 * @param {number} ic5 - ic5 gas value in PPM.
 * @param {number} nc4 - nc4 gas value in PPM.
 * @param {number} nc5 - nc5 gas value in PPM.
 * @param {number} depth - depth of the well.
 * @return {array} result - should return a array with x and y.
 */
export const calculateGeochemistryChart2 = (c1, c2, c3, ic4, ic5, nc4, nc5, depth) => {
  const c4 = ic4 + nc4;
  const c1ByC2 = c2 / c3;
  const c3ByC4 = c3 / c4;
  const resultSF = (c1ByC2 + c3ByC4) / 2;
  const x = resultSF;
  const y = depth;
  return [x, y];
};

/**
 * fn: ignoreNegativeNumbers
 * @param {array} data - array of numbers.
 * @return {array} result - should return a array with numbers negative ignored.
 */
export const ignoreNegativeNumbers = data => {
  return data ? data.map(v => {
    const value = [1, 1];
    if (v[0] >= 0) {
      value[0] = v[0];
    }
    if (v[1] >= 0) {
      value[1] = v[1];
    }
    return value;
  }) : [];
};

/*
X – C1 (N)   Y – C1/C3 (Log)

Oil Zone (1-2-3-4)
1-2: y = 0,3706x-3,657
*/
export const getPointsByC1C45Zones = (
  points,
  p1, p2, p3, p4, // Oil zone
  gasP5, gasP6, gasP7, gasP8,
  transitionP1, transitionP2, transitionP3, transitionP4
) => {
  const result = points.reduce((acc, currentPoint) => {
    const [nX, nY] = currentPoint;
    // Transition
    if (
      nX >= transitionP3.x
      && nX <= transitionP2.x
      && nY <= transitionP4.y
      && nY >= transitionP1.y
    ) {
      if (nX <= transitionP1.x) {
        // equação sup
        if (
          nY <= 1.2515 * Math.pow(nX, 1.1771) // eq sup 
          && nY >= 66656 * Math.pow(nX, -3.657) // 4-3
        ) {
          acc.transition.push([nX, nY]);
          return acc;
        }
      }
      if (nX <= transitionP4.x) {
        // equação sup                             // equação do inf
        if (
          nY <= 1.2515 * Math.pow(nX, 1.1771)
          && nY >= 1.9811 * Math.pow(nX, 0.47)
        ) {
          acc.transition.push([nX, nY]);
          return acc;
        }
      } else {
        // 5-6
        if (
          nY <= 2 * Math.pow(10, 6) * Math.pow(nX, -3.657)
          // inf
          && nY >= 1.9811 * Math.pow(nX, 0.47)
        ) {
          acc.transition.push([nX, nY]);
          return acc;
        }
      }
    }

    // Oil
    if (nX >= p1.x && nX <= p3.x && nY <= p4.y && nY >= p2.y) {
      if (nX <= p2.x) {
        if (
          nY <= 4.4904 * Math.pow(nX, 1.2264) &&
          nY >= 0.3706 * Math.pow(nX, -3.657)
        ) {
          acc.oil.push([nX, nY,]);
          return acc;
        }
      }
      if (nX <= p4.x) {
        if (
          nY <= 4.4904 * Math.pow(nX, 1.2264)
          && nY >= 0.292 * Math.pow(nX, 1.2285)
        ) {
          acc.oil.push([nX, nY]);
          return acc;
        }
      } else {
        // 2-3 
        if (
          nY <= 66656 * Math.pow(nX, -3.657)
          && nY >= 0.292 * Math.pow(nX, 1.2285)
        ) {
          acc.oil.push([nX, nY]);
          return acc;
        }
      }
    }

    /* 
    Gas Zone      Oil Zone
      5       == 4
      6       == 1
      7       == 2
      8       == 3
    */
    if (nX >= gasP5.x && nX <= gasP7.x && nY <= gasP8.y && nY >= gasP6.y) {
      if (nX <= gasP6.x) {
        // 8 - 5                         // 5-6  2E+06x-3,657       
        if (nY <= 10.218 * Math.pow(nX, 1.1454) && nY >= 2 * Math.pow(10, 6) * Math.pow(nX, -3.657)) {
          acc.gas.push([nX, nY]);
          return acc;
        }
      }
      if (nX > gasP6.x && nX <= gasP8.x) {
        // 8-5: y = 10,218x1,1454        // 6-7: y = 0,0589x1,2126
        if (nY <= 10.218 * Math.pow(nX, 1.1454) && nY >= 0.0589 * Math.pow(nX, 1.2126)) {
          acc.gas.push([nX, nY]);
          return acc;
        }
      }
      if (nX > gasP8.x) {
        // 7-8 y = 4E+11x-3,657                         // y = 0,0589x1,2126
        if (nY <= 4 * Math.pow(10, 11) * Math.pow(nX, -3.657) && nY >= 0.0589 * Math.pow(nX, 1.2126)) {
          acc.gas.push([nX, nY]);
          return acc;
        }
      }
    }
    return acc;
  }, { oil: [], transition: [], gas: [] });
  return result;
};

export const getPointsFromC1C3 = (
  points,
  oilP1, oilP2, oilP3, oilP4,
  supMin, supMax, transitionP3, infMin, infMax,
  gasP5, gasP6, gasP7, gasP8,
  equation3To4, equation4To1,
  equation1To2, equation2To3,
  upperEquation, bottomEquation
) => {
  const eulerConstant = 2.71828;
  const result = points.reduce((acc, currentPoint) => {
    const [nX, nY,] = currentPoint;
    if (nX >= oilP4.x && nX <= oilP2.x && nY <= oilP3.y && nY >= oilP1.y) {
      if (nX <= oilP1.x) {
        if (nY <= equation4To1(nX) && nY >= equation1To2(nX)) {
          acc.oil.push([nX, nY]);
          return acc;
        }
      } else if (nX <= oilP3.x) {
        if (nY <= equation4To1(nX) && nY >= equation2To3(nX)) {
          acc.oil.push([nX, nY]);
          return acc;
        }
      } else if (nY <= equation3To4(nX) && nY >= equation2To3(nX)) {
        acc.oil.push([nX, nY]);
        return acc;
      }
    }

    // supMin, supMax, transitionP3, infMin, infMax,
    // x sup min                      // x max inf
    if (nX >= supMin.x && nX <= infMax.x && nY <= supMax.y && nY >= infMin.y) {
      if (nX <= supMax.x) {
        if (nY <= 7 * Math.pow(10, -5) * Math.pow(eulerConstant, 0.1646 * nX)) {
          acc.transition.push([nX, nY]);
          return acc;
        }
      }
      if (nX <= infMin.x) {
        if (nY <= 10385 * Math.pow(eulerConstant, -0.071 * nX)) {
          acc.transition.push([nX, nY]);
          return acc;
        }
      } else if (
        nY <= 10385 * Math.pow(eulerConstant, -0.071 * nX)
        && nY >= 0.0086 * Math.pow(eulerConstant, 0.0846 * nX)
      ) {
        acc.transition.push([nX, nY]);
        return acc;
      }
    }
    // gas
    if (nX >= gasP5.x && nX <= gasP7.x && nY <= gasP8.y && nY >= gasP6.y) {
      if (nX <= gasP6.x) {
        if (
          // 0,0002e0,1516x - 8-5
          nY <= 0.0002 * Math.pow(eulerConstant, 0.1516 * nX)
        ) {
          acc.gas.push([nX, nY]);
          return acc;
        }
      }
      if (nX <= gasP8.x) {
        if (
          // 8-5: 0,0002e0,1516x
          nY <= 0.0002 * Math.pow(eulerConstant, 0.1516 * nX)
          // 6-7: 8E-05e0,1342x
          && nY >= 8 * Math.pow(10, -5) * Math.pow(eulerConstant, 0.1342 * nX)
        ) {
          acc.gas.push([nX, nY]);
          return acc;
        }
      } else if (
        // 7-8: 3E+35e-0,78x
        nY <= 3 * Math.pow(10, 35) * Math.pow(eulerConstant, -0.78 * nX)
        // 6-7: y = 8E-05e0,1342x
        && nY >= 8 * Math.pow(10, -5) * Math.pow(eulerConstant, 0.1342 * nX)
      ) {
        acc.gas.push([nX, nY]);
        return acc;
      }
    }
    return acc;
  }, { oil: [], transition: [], gas: [] });
  return result;
};

export const getPointsC1C2ByC3Zones = (
  points,
  oilP1, oilP2, oilP3, oilP4,
  gasP5, gasP6, gasP7, gasP8,
  bottomMin, bottomMax, upperMin, upperMax
) => {
  const result = points.reduce((acc, currentPoint) => {
    const [nX, nY] = currentPoint;
    // Transition
    if (
      nX >= bottomMin.x
      && nX <= upperMax.x
      && nY <= upperMax.y
      && nY >= bottomMax.y
    ) {
      // exclude by top and bottom
      if (
        // 1-2 
        nY < 8 * Math.pow(10, -5) * Math.pow(nX, 2.5917) &&
        // 5-8
        nY > (6 * Math.pow(10, -6)) * Math.pow(nX, 2.694)
      ) {
        acc.transition.push(currentPoint);
        return acc;
      }
    }

    // Oil
    if (
      nX >= oilP4.x && nX <= oilP2.x && nY <= oilP3.y && nY >= oilP1.y
    ) {
      if (nX <= oilP3.x) {
        if (
          // 3-4: 128,05x2,6936
          nY <= 128.05 * Math.pow(nX, 2.6936) &&
          // 4-1: y = 0,5621x-1,815
          nY >= 0.5621 * Math.pow(nX, -1.815)
        ) {
          acc.oil.push([nX, nY]);
          return acc
        }
      }
      if (nX <= oilP1.x) {
        if (
          // 2-3: 68,463x-1,803 
          nY <= (68.463 * Math.pow(nX, -1.803)) &&
          // 4-1: y = 0,5621x-1,815
          nY >= (0.5621 * Math.pow(nX, -1.815))
        ) {
          acc.oil.push([nX, nY]);
          return acc;
        }
      } else if (
        // 2-3: 68,463x-1,803 
        nY <= (68.463 * Math.pow(nX, -1.803)) &&
        // 3-4: y = 128,05x2,6936
        nY >= 8 * Math.pow(10, -5) * Math.pow(nX, 2.5917)
      ) {
        acc.oil.push([nX, nY]);
        return acc;
      }
    }

    /*
      Gas Zone (5-6-7-8)
      5-6: y = 1,4569x-1,712
      6-7: y = 5E-11x2,8419
      7-8: y = 126,13x-1,697
      8-5: y = 6E-06x2,694
    */
    if (nX >= gasP5.x && nX <= gasP7.x && nY <= gasP8.y && nY >= gasP6.y) {
      if (nX <= gasP8.x) {
        if (
          // 6-7
          nY <= (5 * Math.pow(10, -11)) * Math.pow(nX, -2.8419) &&
          // 5-6 
          nY >= 1.4569 * Math.pow(nX, 1.712)
        ) {
          acc.gas.push([nX, nY]);
          return acc;
        }
      }
      if (nX <= gasP6.x) {
        if (
          // 7-8
          nY <= 126.13 * Math.pow(nX, -1.697) &&
          // 5-6
          nY >= 1.4569 * Math.pow(nX, -1.712)
        ) {
          acc.gas.push([nX, nY]);
          return acc;
        }
      } else if (
        // 7-8 
        nY <= 126.13 * Math.pow(nX, -1.697) &&
        // 8-5
        nY >= (6 * Math.pow(10, -6)) * Math.pow(nX, 2.694)
      ) {
        acc.gas.push([nX, nY]);
        return acc;
      }
    }
    return acc;
  }, { oil: [], transition: [], gas: [] });
  return result;
};

// getWetnessPointsByZone
export const getWetnessPointsByZones = (
  points,
  oilP1, oilP2, oilP3, oilP4,
  gasP5, gasP6, gasP7, gasP8
) => {
  const result = points.reduce((acc, currentPoint) => {
    const [nX, nY,] = currentPoint;
    // Transition
    if (
      nX >= oilP1.x
      && nX <= gasP8.x
      && nY <= oilP2.y
      && nY >= gasP5.y
    ) {
      if (nX <= oilP2.x) {
        if (
          nY <= (11.064 * Math.log(nX)) + 4.5848
          && nY >= (-19.31 * Math.log(nX)) + 49.093
        ) {
          acc.transition.push([nX, nY]);
          return acc;
        }
      }

      if (nX <= gasP5.x) {
        if (
          nY <= (-5.938 * Math.log(nX)) + 40.567
          && nY >= (-19.31 * Math.log(nX)) + 49.093
        ) {
          acc.transition.push([nX, nY,]);
          return acc;
        }
      } else if (
        nY <= (-5.938 * Math.log(nX)) + 40.567 &&
        nY >= (117.98 * Math.log(nX)) - 280.09
      ) {
        acc.transition.push([nX, nY]);
        return acc;
      }
    }

    // // Oil
    if (
      nX >= oilP4.x && nX <= oilP2.x && nY <= oilP3.y && nY >= oilP1.y
    ) {
      if (nX <= oilP3.x) {
        if (
          // 3-4 
          nY <= (11.064 * Math.log(nX)) + 106.92 &&
          // 4-1 
          nY >= (-22.11 * Math.log(nX)) + 53.198
        ) {
          acc.oil.push([nX, nY]);
          return acc;
        }
      }
      if (nX <= oilP1.x) {
        if (
          // 2-3
          nY <= (-22.4 * Math.log(nX)) + 75.407 &&
          // 4-1
          nY >= (-22.11 * Math.log(nX)) + 53.198
        ) {
          acc.oil.push([nX, nY]);
          return acc;
        }
      } else if (
        // 2-3
        nY <= (-22.4 * Math.log(nX)) + 75.407 &&
        // 3-4
        nY >= (11.064 * Math.log(nX)) + 4.5848
      ) {
        acc.oil.push([nX, nY]);
        return acc;
      }
    }

    // Gas
    if (nX >= gasP5.x && nX <= gasP7.x && nY <= gasP8.y && nY >= gasP6.y) {
      if (nX <= gasP8.x) {
        if (
          // 6-7
          nY <= (117.98 * Math.log(nX)) - 280.09 &&
          // 5-6 
          nY >= (-0.748 * Math.log(nX)) + 4.5932
        ) {
          acc.gas.push([nX, nY]);
          return acc;
        }
      }
      if (nX <= gasP6.x) {
        if (
          // 7-8
          nY <= (-2.079 * Math.log(nX)) + 30.58 &&
          // 5-6
          nY >= (-0.748 * Math.log(nX)) + 4.5932
        ) {
          acc.gas.push([nX, nY]);
          return acc;
        }
      } else if (
        // 7-8 
        nY <= (-2.079 * Math.log(nX)) + 30.58 &&
        // 8-5
        nY >= (117.98 * Math.log(nX)) - 718.82
      ) {
        acc.gas.push([nX, nY]);
        return acc;
      }
    }
    return acc;
  }, { oil: [], transition: [], gas: [] });
  return result;
};

/*
 * Prevent to return a array with 0 zero value, this reason is to avoid error
 * in Highcharts on the logarithmic scale no accept empty values
 * */
/**
 * fn: filterToAvoidZeroYAxis
 * @param {array} collection - array of number.
 * @return {array} result - should return a array with 0 in y axis. 
 */
export const filterToAvoidZeroYAxis = (collection, min = 0.00001) => collection.map(value => {
  if (value[0] === 0 || isNaN(value[0]) || value[0] === null) {
    return [min, value[1]];
  }
  return value;
});

/*
 * Prevent to return a array with 0 value, this reason is to avoid error
 * in Highcharts on the logarithmic scale no accept empty values
 * */
/**
 * fn: filterToAvoidZeroXAxis
 * @param {array} collection - array of number.
 * @return {array} result - should return a array with 0 in x axis. 
 */
export const filterToAvoidZeroXAxis = (collection, min = 0.00001) => collection.map(value => {
  if (value[1] === 0 || isNaN(value[1]) || value[0] === null) {
    return [value[0], min];
  }
  return value;
});

/*
 * getKeyForCurrentUser
 * @description: The purpose of this function is to access a key that is used as an id to access
 *               the well stored associated with some user.
 * @param {string} key - string to access on localStorage
 * @return {string} value - should return value within the property on localStorage
 */
export const getKeyForCurrentUser = key => `${key}_${store.get('well-user')}`;

export const findOnIndexDB = (key) => {
  return new Promise((resolve) => {
    getFromIndexDB(key).then((value) => {
      if (value) {
        // Encode legacy data and move to new key
        updateOnIndexDB(getKeyForCurrentUser(key), btoa(JSON.stringify(value)));
        delFromIndexedDB(key);
        resolve(value);
      } else {
        // No legacy data, get from new key with username
        getFromIndexDB(getKeyForCurrentUser(key)).then((value) => {
          resolve(value ? JSON.parse(atob(value)) : value);
        });
      }
    });
  });
};

export const setOnIndexDB = (key, value) => {
  updateOnIndexDB(
    getKeyForCurrentUser(key),
    btoa(JSON.stringify(value))
  );
};

export const generateConfigPlotLine = (type, value) => ({
  value,
  width: 2,
  color: '#000',
  id: type,
  dashStyle: 'dash',
  label: {
    text: ''
  },
  zIndex: 500
});

/*
 * fn: generatePlotLineCallback
 * @param {number} min - min value to add the line
 * @param {number} max = max value to apply the line 
 * @return {number} undefined
 */
export const applyPlotLine = generatePlotLineCallback => (min, max, chart) => {
  const axis = chart.xAxis[0];
  axis.removePlotLine('min');
  axis.removePlotLine('max');

  const plotMin = generatePlotLineCallback('min', min);
  const plotMax = generatePlotLineCallback('max', max);

  axis.addPlotLine(plotMin);
  axis.addPlotLine(plotMax);
  chart.redraw();
};

export const createPlotLine = applyPlotLine(generateConfigPlotLine);

export const errorStyle = {
  color: '#bd3636',
  fontSize: '14px',
  textAlign: 'center'
};

/**
 * fn: avoidZero
 * @param {number} value
 * @return {number} value - should ensure that return not a zero value
 */
export const avoidZero = value => {
  if (value === 0 || isNaN(value) || value === null) {
    return 0.0001;
  }
  return value;
};

/**
 * fn: openNotify
 * @param {function} cbNofication - callback modules to call within
 * @param {string} message
 * @param {string} description
 * @param {string} placement
 * @return should return a notification
 *         message setting type, information and position of the box
 */
export const openNotify = cbNotification => (
  message,
  description = '',
  placement = 'topRight',
  duration = 5
) => {
  cbNotification({
    message,
    description,
    placement,
    duration
  });
};

export const defaultNotification = openNotify(notification.open);
export const successNotification = openNotify(notification.success);
export const errorNotification = openNotify(notification.error);
export const warningNotification = openNotify(notification.warning);
export const closeNotification = openNotify(notification.close);

/**
 * fn: calculateGasComposition
 * @param {number} c1 - c1 gas value in PPM.
 * @param {number} c2 - c2 gas value in PPM.
 * @param {number} c3 - c3 gas value in PPM.
 * @param {number} nc4 - nc4 gas value in PPM. 
 * @param {number} nc5 - nc5 gas value in PPM. 
 * @param {number} ic4 - ic4 gas value in PPM. 
 * @param {number} ic5 - ic5 gas value in PPM.  
 * @return {number} resultGas - should return result gas in percentage.
 */
export const calculateGasComposition = (c1, c2, c3, nc4, nc5, ic4, ic5) => currentGas => {
  const gas = currentGas < 0 ? 0 : currentGas;
  const sumOfGases = c1 + c2 + c3 + nc4 + ic4 + nc5 + ic5;
  const resultGas = gas / sumOfGases * 100;
  return isNaN(resultGas) ? 0 : resultGas;
};

/**
 * fn: defaultValuesToCrosshair
 * @param {array} data - receive an array of depth items 
 * @param {number} defaultValue
 * @return {array} defaultValue - returns a range of values with default value.
 */
export const defaultValuesToCrosshair = (data, defaultValue = 0) => [
  ...Array(data && data.length ? data.length : 0).keys(),
].map(x => defaultValue);

/*
 * getGreaterValue
 * @Description: receive a object to find the greater value and return
 * @params: {object} - object - receive all diffs between the depth
 * @return: {Object} { 0.25: 58 }
 */
const getGreaterValueByObject = stepsFound => {
  return keys(stepsFound).reduce((acc, item) => {
    const value = stepsFound[item];
    if (value > acc) {
      acc = item;
    }
    return acc;
  }, 0);
};

/*
 * checkOccurrences
 * @Description: receive a list with values and count
 *               each ocuccrence of this value
 * @params: {Array} - steps - receive all diffs between the depth
 * @return: {Object} { 0.25: 50, 0.39: 20 }
 */
const checkOccurrences = steps => {
  return steps.reduce((acc, step) => {
    const ocurrence = acc[step];
    if (!ocurrence) {
      acc[step] = 1;
    } else {
      acc[step] = ocurrence + 1;
    }
    return acc;
  }, {});
};

/*
 * calculateSteps
 * @Description: Calculate the difference between the values
 *               to get the step between each depth value.
 * @params: {Array} - depth - receive all depth value
 * @return: {Number}
 */
export const calculateSteps = depth => {
  let currentStep = 1;

  const steps = depth.reduce((acc, currentDepth, index) => {
    const nextValue = depth[index + 1];
    if (nextValue >= 0) {
      const diff = nextValue - currentDepth;
      // Get just the decimal values
      if (!Number.isInteger(diff)) {
        acc.push(diff);
      }
    }
    return acc;
  }, []);

  const resultOccurrences = checkOccurrences(steps);
  const step = getGreaterValueByObject(resultOccurrences);

  if (step && step > 0) {
    currentStep = step;
  }

  return currentStep;
};

/*
 * arrayFlipToObject 
 * @Description: should swap array the key and value create a object
 *               Such as: [key: value] -> { value->key }
 * @params: {Array} - json - receive key and values 
 * @return: {Object} - swapped with swapped values
 */
export const arrayFlipToObject = list => {
  return Object.keys(list).reduce((acc, item, index) => {
    const key = item;
    const value = new String(list[item]);
    acc[value] = key;
    return acc;
  }, {});
};

/*
* filterOption
*  -The function should receives two values and if exists options returns true,
*  if not exists returns false
*/
export const filterOption = (input, option) =>
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

/**
 * getObjectValues
 * @description This function get an array of keys with Object.keys() and then 
 * use method map() to get values of dataObject
 * @param {Object} dataObject
 */
export const getObjectValues = dataObject =>
  Object.keys(dataObject).map(key => dataObject[key]);

export const formattingMin = min => {
  const minValue = String(min);
  let value = 0.1;
  if (!min) {
    return value;
  }
  // if is a invalid value 0.
  // if is a int value withou dot (.)
  const splited = String(minValue).split('.');
  if (
    (minValue && splited.length === 2 && minValue >= 0.1) ||
    (!minValue.includes('.') && min >= 0.1)
  ) {
    value = min;
  }
  return value;
};

export const getEthenenAndDepth = (depth, c2 = [], c2s = [], c2h6 = [], c2h4 = []) => {
  const values = [];
  depth.forEach((depthValue, index) => {
    const c2fidValue = calculateEthene(1, c2[index], c2s[index], c2h6[index], c2h4[index]);
    values.push([depthValue, c2fidValue]);
  });
  return values;
};

/*
* generateWellOptions
*  -Should receive the well items access each name
*   and generate a object { label, value, id }
*/
export const generateWellOptions = wells => {
  const wellOptions = [];
  const wellNames = [];
  for (const wellKey in wells) {
    const well = wells[wellKey];
    if (well && well.currentWell) {
      wellOptions.push({
        label: well.name,
        value: well.well_uid,
        id: well.well_uid,
      });
      wellNames.push(well.name);
    }
  }
  return {
    wellOptions,
    wellNames
  };
}

export const generateWellOptionsFromApi = wells => {
  const wellOptions = [];
  const wellNames = [];
  for (const wellKey in wells) {
    const well = wells[wellKey];
    if (well && well.currentWell) {
      wellOptions.push({
        label: well.wellName,
        value: well.wellId,
        id: well.wellId,
      });
      wellNames.push(well.wellName);
    }
  }
  return {
    wellOptions,
    wellNames
  };
}

// Check if is a valid top and bottom to cut by index
export const checkIfThereAreTopAndBottom = (depth, top, bottom) => {
  return depth.reduce((acc, depth, index) => {
    if (Math.round(top) === Math.round(depth)) {
      acc.top.isValid = true;
      acc.top.index = index;
    }
    if (Math.round(bottom) === Math.round(depth)) {
      acc.bottom.isValid = true;
      acc.bottom.index = index;
    }
    return acc;
  }, {
    top: { isValid: false, index: 0, path: 'top' },
    bottom: { isValid: false, index: 0, path: 'bottom' }
  });
};

export const keysFromObjectToArray = (obj) => {
  const arr = [];
  for (const key in obj) {
    if (obj[key] !== null) {
      arr.push(key);
    }
  }
  return arr;
};

export function ErrorMessage({ message }) {
  return <span className="message-error">{message}</span>
};

export const getLithoPercentage = ({ percentage }) =>
  percentage === 'TRS' || percentage === 'RRS' ? 0 : Number(percentage.split('P')[1]);

/*
 * lithoDataFormatter
 * @arguments: {Array} - all depth object with lithology values
 * @description: Should access each object and check the range top and bottom
 *               to fill some the range. When we are calling the api
 *               we gonna receive a object with a range of depth
 *               and the values that are repeated.
 *               We need to format it and tranform in a array specifying
 *               what values are in each depth not a range.
 *               This happens because the litology range could variate
 *               between the time to get the samples.
 *
 * Input Format:
 * [
 *  {
 *    bottom: 1284,
 *    free_description: null,
 *    id: 298,
 *    no_return_interval: true,
 *    top: 1281,
 *    user_id: null,
 *    lithologies: [
 *      { lithology: "SANDSTONE", percentage: "P70" },
 *      { lithology: "CALCILUTITE", percentage: "P10" },
 *      { lithology: "SHALE", percentage: "P20" }
 *    ]
 * ]
 *
 * Output:
 * {
 *   rock_calcilutite: [[1281, 10], [1282, 10], [1283, 10], [1284, 10]],
 *   rock_sandstone: [[1281, 70], [1282, 70], [1283, 70], [1284, 70]],
 *   rock_shale: [[1281, 20], [1282, 20], [1283, 20], [1284, 20]]
 * }
 */
export const lithoDataFormatter = (data) =>
  data.reduce((acc, row) => {
    const rows = [];
    const start = Number(row.top);
    const end = Number(row.bottom || row.base);
    const len = end - start;
    for (let i = 0; i < len; i++) {
      const currentDepth = i === 0 ? start : start + i;
      row.lithologies.reduce((acc1, litho) => {
        const lithoKey = `rock_${litho.lithology.toLowerCase()}`;
        acc[lithoKey] = [...(acc[lithoKey] || [])];
        rows.push(currentDepth);

        const percentage = getLithoPercentage(row);
        acc[lithoKey].push([currentDepth, percentage || 0]);
        return acc1;
      }, {});
    }
    return acc;
  }, {});

/*
 * lithoDataFormatter
 * @arguments: {Array} - The depth object with real time interpreted lithology values
 * @description: Should access each object and store the depth and the
 *               percentage 100%, this number represents that the rock
 *               exists at this depth.
 *
 * Input Format:
 * [
 *  {
 *    depth: 1831,
 *    id: 1281,
 *    lithology: "SANDSTONE"
 * ]
 *
 * Output:
 * {
 *   SANDSTONE: [[1831, 100], [1834, 100], [1835, 100]],
 *   SHALE: [[1830, 100], [1832, 100], [1833, 100]],
 * }
 */


export const GeoCopilotLithoDataFormatter = (data) =>
  data.reduce((acc, row) => {
    const rows = [];
    const start = Number(row.top);
    const end = Number(row.base);

    for (let i = start; i < end; i++) {
      const currentDepth = i === 0 ? start : start + i;
      const lithoKey = row.lithology.toLowerCase();
      acc[lithoKey] = [...(acc[lithoKey] || [])];
      rows.push(currentDepth);
      const percentage = 100;
      acc[lithoKey].push([currentDepth, percentage || 0]);
    }
    return acc;
  }, {});


export const realTimeLithoDataFormatter = (data, depth) => {
  const result = Object.keys(data).reduce((acc, abbreviation) => {
    const lithoKey = ABBREVS_ROCKS_NAMES[abbreviation]
      ? ABBREVS_ROCKS_NAMES[abbreviation] : null;
    if (lithoKey && data[abbreviation]) {
      acc[lithoKey] = [...(acc[lithoKey] || [])];
      const valueWithDepth = (data && data[abbreviation] ? data[abbreviation] : []);
      const values = valueWithDepth.map(v => v);
      acc[lithoKey] = values;
    }
    return acc;
  }, {});
  return result;
};

export const lithoScoreFormatter = (data, depth) => {
  const result = Object.keys(data).reduce((acc, abbreviation) => {
    const lithoKey = ABBREVS_ROCKS_NAMES[abbreviation]
      ? ABBREVS_ROCKS_NAMES[abbreviation] : null;
    if (lithoKey) {
      acc[lithoKey] = [...(acc[lithoKey] || [])];
      const valueWithDepth = (data[abbreviation] || [])
        .map(v => v);
      acc[lithoKey] = valueWithDepth;
    }
    return acc;
  }, {});
  return result;
};


export function polling(callback, delay = 1000, timeout = 70000) {
  let endTime = Date.now() + timeout;
  let pollId = null;
  return (() => {
    pollId = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime > endTime) {
        callback(pollId);
        endTime += timeout;
      }
    }, delay);
    return pollId;
  })();
}

// The function return the curve with the most data, this prevent crosshair disappearing
export function maxCurveLithology(data) {
  const shale = data.rock_shale && data.rock_shale.length ? data.rock_shale : [];
  const sandstone = data.rock_sandstone && data.rock_sandstone.length ? data.rock_sandstone : [];
  const claystone = data.rock_claystone && data.rock_claystone.length ? data.rock_claystone : [];
  const calcarenite = data.rock_calcarenite && data.rock_calcarenite.length ? data.rock_calcarenite : [];
  const calcilutite = data.rock_calcilutite && data.rock_calcilutite.length ? data.rock_calcilutite : [];
  const siltstone = data.rock_siltstone && data.rock_siltstone.length ? data.rock_siltstone : [];
  const halite = data.rock_halite && data.rock_halite.length ? data.rock_halite : [];
  const anhydrite = data.rock_anhydrite && data.rock_anhydrite.length ? data.rock_anhydrite : [];

  const curvesArrayLength = [shale.length, sandstone.length, claystone.length, calcarenite.length, calcilutite.length, siltstone.length, halite.length, anhydrite.length];
  const curvesArray = [shale, sandstone, claystone, calcarenite, calcilutite, siltstone, halite, anhydrite];

  //Get the maximum length of the curves 
  const maxCurveLength = Math.max(...curvesArrayLength);

  //Find the curve with the maximum length
  const maxCurve = curvesArray.find(element => element.length === maxCurveLength);

  return maxCurve;
}

//Import all files from a single folder and return it as an array
//about the usage check the require.context from webpack: https://webpack.js.org/guides/dependency-management/#require-context
export function importAll(r) {
  let files = {};
  r.keys().map((item, index) => { files[item.replace('./', '')] = r(item); });
  return files;
}