import React from 'react';
import store from 'store';

import { DEFAULT_SCALES } from 'utils/constants';
import { Login as LoginModule } from '@geowellex/shared-login';
import { useService } from '@geowellex/shared-login/src/hooks/useService';

import LogoGeowellex from 'images/logo-geowellex-white.svg';
import LogoWellexGold from 'images/logo-automud-white.svg';

import image1 from '../../images/carousel-start/img-01.svg';
import image2 from '../../images/carousel-start/img-02.svg';
import image3 from '../../images/carousel-start/img-03.svg';

import bgLogin from 'images/bg-login.svg';

import screen from 'images/automud-screen.svg';

const Login = () => {
  const carouselData = [
    { title: "AUTOMUD: Advanced analysis of Mud Logging in real time", image: image3 },
    { title: "Advanced models for calculating and analyzing drilling data", image: image1 },
    {
      title: "Obtain precise data about rheology and hydraulics, geopressure and interpreted lithology for your wells", 
      image: image2
    }
  ];

  const colors = {
    loginBg: '#151724',
    loginButton: '#233BB6'
  }

  const { setService } = useService();

  setService('AUTOMUD');

  const configScales = () => {
    const configScales = store.get('configScales');
    if (!configScales || configScales && !configScales.aiGrPrediction) {
      store.set('configScales', DEFAULT_SCALES);
    }
  };

  return (
    <LoginModule
      title="Welcome"
      subtitle="Log in to your account"
      logoOnCopyright={LogoGeowellex}
      logoOnForm={LogoWellexGold}
      carousel={carouselData}
      configScales={configScales}
      bg={bgLogin}
      screen={screen}
      colors={colors}
    />
  );
};

export default Login;
