import React, { useState, useRef, useEffect } from 'react'
import { Box } from '..'
import { Select } from 'antd'
import { StyledSelect } from './styles';

const { Option } = Select;

function MultiSelectCombobox({ label, options, initialValue, width, clear, onSelect, disabled, items, setItems, type, ...props }) {
    const [selectedItems, setSelectedItems] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);

    const filterOptions = (options, filterProp, include) => {
        if (!include) {
            return options.filter((o) => !filterProp.includes(o.id))
        }
        return options.filter((o) => filterProp.includes(o.id));
    }

    const handleChange = (selectedItems) => {
        setSelectedItems(selectedItems);
        setItems !== undefined && setItems(selectedItems);
        onSelect(filterOptions(options, selectedItems, true));
    };

    const orderedOptions = (options) => {
        return options.sort((x, y) => {
            let a = x.label;
            let b = y.label;
            return a == b ? 0 : a > b ? 1 : -1;
        })
    };


    useEffect(() => {
        setSelectedItems([])
        setItems !== undefined && setItems([])
    }, [clear]);

    useEffect(() => {
        setFilteredOptions(filterOptions(options, selectedItems, false));
    }, [selectedItems, options]);

    return (
        <Box flex="1" position="relative">
            {label}
            <StyledSelect
                mode="multiple"
                value={type === 'custom' ? items : selectedItems}
                onChange={handleChange}
                style={{ width: '100%' }}
                {...props}
            >
                {orderedOptions(filteredOptions).map((item) => (
                    <Option key={item.id} value={item.id} data-cy='multi-select-option'>
                        {item.label}
                    </Option>
                ))}
            </StyledSelect>
        </Box >
    );
};

export default MultiSelectCombobox;
