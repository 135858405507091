import {
  fetchModels,
  fetchOneModel,
  fetchRunOneMetadata,
} from '../api/models';
import * as actions from '../constants/models';
import store from 'store';

export const receiveModels = models => ({
  type: actions.LIST_MODELS,
  models,
});

export const receiveOneModel = (model, id) => ({
  type: actions.LIST_ONE_MODEL,
  model,
  id
});

export const receiveOneMetadata = (metrics, runId) => ({
  type: actions.LIST_ONE_METADATA,
  metrics,
  runId
});

export const listModels = (afterSuccess) => {
  return async (dispatch) => {
    try {
      const response = await fetchModels(store.get('token'));
      dispatch(receiveModels(response.data.models));
    } catch (e) {
      console.log('error listExperiments', e)
    } finally {
      afterSuccess();
    }
  };
};

export const listOneModel = (name) => {
  return async (dispatch, getState) => {
    const models = getState().models.items;
    const currentModel = models[name];
    // try {
    //   const response = await fetchOneModel(name);
    //   dispatch(receiveOneModel(response.data, currentModel.id));
    // } catch (e) {
    //   console.log('error listExperiments', e)
    // }
  };
};

export const listRunOneMetadata = (runId, name) => {
  return async (dispatch, getState) => {
    const models = getState().models.items;
    const currentModel = models[name];
    try {
      const response = await fetchRunOneMetadata(runId);
      dispatch(receiveOneMetadata(response.data, currentModel));
    } catch (e) {
      console.log('error listExperiments', e)
    }
  };
};




