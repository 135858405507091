import React, { useState, useCallback, useEffect } from 'react'

import useExperiments from '../../../hooks/useExperiments'

import { Container, SelectStyled } from './styles.js';
import Spinner from 'components/Spinner';
import { TextArea, Combobox, Button, Box, Text, Select, SelectedItems, MultiSelectCombobox } from '../../../components';
import { useSelector } from 'react-redux';

const { Option } = SelectStyled;

const parseData = (data) => {
  const fields = Object.keys(data)

  return fields.map(field => ({ label: data[field].display_name, id: data[field].experiment_id }))
}

const Experiment = () => {
  const {
    experiments: experimentsRAW,
    well,
    wells,
    logName,
    handleChangeLogName,
    curve,
    curves,
    mnemonicsOptions,
    setCurve,
    loadingWells,
    selectedCurves,
    setSelectedCurves,
    availableLogs,
    selectedWells,
    handleConfirm,
    handleTrainingModel,
    handleChange,
    handleDescription,
    handleSelectedWells,
    addSelectedCurve,
    removeSelectedCurve,
    handleLogSetSelection,
    outputLog,
    handleOutputCurveSelection,
    outputCurve
  } = useExperiments()

  const [currentSelectedWells, setCurrentSelectedWells] = useState([])
  const [currentSelectedCurves, setCurrentSelectedCurves] = useState([])
  const [clearWells, setClearWells] = useState(false)
  const [clearCurves, setClearCurves] = useState(false)
  const [wellsWarning, setWellsWarning] = useState(false)
  const [selectedInputCurves, setSelectedInputCurves] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [prevSelectedItems, setPrevSelectedItems] = useState([])
  const models = useSelector(state => state.models) || {};
  const modelsList = Object.keys(models.items).map((item, index) => ({ id: index, label: item }));

  const logs = Object.keys(well.currentWell.log_sets);

  const experiments = useCallback(parseData(experimentsRAW), [experimentsRAW]);

  const parseWellsData = useCallback(() => {
    const notSelectedWells = wells.filter((well) => !selectedWells.find((selectedWell) => well.uidWell === selectedWell.uidWell))

    return notSelectedWells.map((well) => ({ id: well.uid, label: well.name }))
  }, [wells, selectedWells]);

  useEffect(() => {
    if (outputLog) {
      const notSelectedWells = wells.filter((well) => !selectedWells.find((selectedWell) => well.uidWell === selectedWell.uidWell));
      setWellsWarning(notSelectedWells.length === 0);
    }
  }, [wells, selectedWells]);

  const handleSelectedWellAndCleanState = () => {
    setClearWells(!clearWells)
    currentSelectedWells.forEach((item) => {
      handleSelectedWells(item);
    });
    setCurrentSelectedWells([]);
  };

  const filterRepeatedCurves = (inputCurves, selectedCurves) => {
    const filteredCurves = {};

    for (const key in inputCurves) {
      if (inputCurves.hasOwnProperty(key)) {
        filteredCurves[key] = inputCurves[key].filter(value => !selectedCurves[key]?.includes(value));
      }
    }

    return filteredCurves;
  };

  const handleSelectedCurves = (inputCurves) => {
    setClearCurves(!clearCurves)
    const filteredCurves = filterRepeatedCurves(inputCurves, selectedCurves);

    const curvesArray = [];

    for (const log in filteredCurves) {
      filteredCurves[log].forEach(curve => {
        curvesArray.push({ log: log, curve: curve });
      });
    }

    curvesArray.forEach((item) => {
      addSelectedCurve(item.log, item.curve);
    })

    setSelectedInputCurves([])
    setCurrentSelectedCurves([])
  };


  const parseShowCurves = () => {
    const availableCurves = Object.keys(selectedCurves).filter((logName) => !selectedCurves[logName].find((item) => {
      (item === curve.label)
    }))

    const parseCurve = availableCurves.map((logName) => {
      return (selectedCurves[logName]).map((curves) => (
        curves
      ))
    })

    return parseCurve
  };

  const addInputCurves = (curves) => {
    const selectedCurves = curves.map(curve => curve.label);

    setSelectedInputCurves(prevState => {
      const currentLogSetCurves = prevState[availableLogs.currentLog] || [];
      const updatedCurves = [...new Set([...currentLogSetCurves, ...selectedCurves])];

      return {
        ...prevState,
        [availableLogs.currentLog]: updatedCurves
      };
    });

    setPrevSelectedItems(selectedItems);

  };

  useEffect(() => {
    if (selectedItems.length < prevSelectedItems.length) {
      const removedItem = prevSelectedItems.filter(item => !selectedItems.includes(item));

      const updatedCurves = {};
      Object.keys(selectedInputCurves).forEach(log => {
        const updatedLogCurves = selectedInputCurves[log].filter(curve => curve !== removedItem[0]);
        updatedCurves[log] = updatedLogCurves;
      });

      setSelectedInputCurves(prevState => ({
        ...prevState,
        ...updatedCurves
      }));
    }
  }, [selectedItems, prevSelectedItems]);

  return (
    <Container>
      <Combobox
        label="Model Name"
        options={modelsList}
        onSelect={(value) => handleTrainingModel('name', value)}
        onChange={(e) => handleChange('name', e)}
        placeholder="Type a new model or select from one that exists"
        required
        data-cy="model-name"
      />
      <Box mt="1.5rem">
        <Text variant="subtitle">Description</Text>
        <TextArea onChange={(item) => handleDescription(item)} placeholder="e.g. 'Gamma Ray predictor trained with wells X, Y and Z'" />
      </Box>
      <Box mt="1.5rem">
        <Text variant="subtitle">Output Curve</Text>
        <Box display="flex">
          <Box flex="1" mr="1rem">
            <SelectStyled
              options={logs}
              onSelect={(value) => handleLogSetSelection(value)}
              onChange={(value) => handleLogSetSelection(value)}
              data-cy="output-log"
            >
              {logs.map((item) => (<Option key={item} value={item} data-cy='option-output-log'>{item}</Option>))}
            </SelectStyled>
          </Box>
          <Box flex="1" ml="1rem">
            <SelectStyled
              mode='combobox'
              options={mnemonicsOptions}
              onSelect={handleOutputCurveSelection}
              onChange={handleOutputCurveSelection}
              placeholder="Type a new curve or select from one that exists"
              required
              data-cy="output-curve"
            >
              {mnemonicsOptions.map((item) => (<Option key={item.id} value={item.label} data-cy='option-output-curve'>{item.label}</Option>))}
            </SelectStyled>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="flex-end" mt="1.5rem">
        <Box flex="1" mr="1rem">
          {loadingWells ?
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '8px' }}>
              <Spinner classes="spinner--small" />
            </div>
            :
            <MultiSelectCombobox
              label="Select Wells"
              options={parseWellsData()}
              clear={clearWells}
              onSelect={(item) => setCurrentSelectedWells(item)}
              disabled={!wells.length}
              data-cy="select-wells"
            />}
        </Box>
        <Button
          variant="outlined"
          onClick={handleSelectedWellAndCleanState}
          disabled={!currentSelectedWells.length}
          data-cy="add-well-button"
        >Add</Button>
      </Box>
      <Box mb="1.5rem" mt="0.7rem">
        {wellsWarning && !loadingWells && selectedWells?.length === 0 && <Text style={{ color: 'red', fontSize: 14 }}>No wells available</Text>}
      </Box>
      <SelectedItems
        title="Selected Wells:"
        data={selectedWells
          .map(({ uid, name }) =>
            ({ id: uid, field: name })
          )
        }
        onRemove={handleSelectedWells}
      />
      <Box display="flex" mt="0.5rem">
        <Button
          flex="1"
          variant="primary"
          onClick={handleConfirm}
          disabled={!selectedWells.length}
          data-cy="confirm-wells-button"
        >Select Wells</Button>
      </Box>
      {availableLogs.logs.length ?
        <Box mt="1.5rem" mb="1.5rem">
          <Text variant="subtitle">Input Schema Definition</Text>
          <Box
            display="flex"
            alignItems="flex-end"
            mt=".5rem"
            mb="1rem"
          >
            <Box flexGrow="1" minWidth="150px" mr="1rem">
              <Select
                label='Log Name'
                options={availableLogs.logs}
                onSelect={handleChangeLogName}
                disabled={!availableLogs.logs.length}
                data-cy="input-log"
              />
            </Box>
            <Box flexGrow="1" mr="1rem">
              <MultiSelectCombobox
                label='Select Curve'
                options={availableLogs.currentLogCurves}
                onSelect={i => addInputCurves(i)}
                disabled={!availableLogs.currentLogCurves.length}
                clear={clearCurves}
                items={selectedItems}
                setItems={setSelectedItems}
                type='custom'
                data-cy="input-curve"
              />
            </Box>
            <Button
              variant="outlined"
              onClick={() => handleSelectedCurves(selectedInputCurves)}
              disabled={!availableLogs.currentLog || !currentSelectedCurves}
              data-cy="add-curve-button"
            >Add</Button>
          </Box>
          <Box display="flex" mt="0.5rem" flexDirection="column">
            <SelectedItems
              title="Selected curves:"
              data={curves}
              onRemove={removeSelectedCurve}
            />
          </Box>
        </Box>
        : null
      }
    </Container>
  )
}

export default Experiment