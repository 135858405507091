import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import { listModels } from '../../actions/models'

import { Combobox, Image, Text, TextArea, Button, Box, Select } from '../../components'
import Spinner from '../../components/Spinner'
import store from 'store';

const Content = styled.div`
  background: rgb(29, 28, 28);
  padding: 2rem 4rem;

  .spinner {
    width: 26px;
    height: 26px;
  }
`

const AddModel = ({
  data,
  onSubmit,
  onClose,
  onChange
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const models = useSelector((state) => state.models.items)
  const parsedModels = Object.keys(models).map((modelName) => ({ id: models[modelName].id, label: modelName }))

  const handleClose = () => {
    onClose && onClose()
  }

  const fetchModels = () => {
    setIsLoading(true)
    dispatch(listModels(() => setIsLoading(false)))
  }

  useEffect(() => {
    fetchModels(store.get('token'));
  }, [])

  const renderTextButton = () => {
    if (isLoading) return <Spinner classes="spinner--small" />

    return 'Add Model'
  }

  const handleClick = () => {
    setIsLoading(true)

    return onSubmit(() => setIsLoading(false))
  }

  const disableSubmit = () => {
    if (!!data) {
      const { title, modelType } = data

      if (!!title && !!modelType) {
        if (!!title.length || !!modelType.length) return false
      }
    }

    return true
  }

  return (
    <Content display="flex" flexDirection="column" p="0 8rem">
      <Box display="flex" justifyContent="space-between" mb={40}>
        <Text variant="title">Add Model</Text>
        <Image name="close" width="14" height="14" onClick={handleClose} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Combobox
          disabled={isLoading}
          label="Name"
          options={parsedModels}
          onSelect={({ label }) => onChange('title', label)}
          onChange={(value) => onChange('title', value)}
          placeholder="Type a new model or select from one that exists"
        />
        <Box display="flex" flexDirection="column" mt={20} mb={20}>
          <Text variant="subtitle">Description</Text>
          <TextArea
            value={data && data.description}
            onChange={(event) => onChange('modelDescription', event.target.value)}
            placeholder="e.g. 'Gamma Ray predictor trained with wells X, Y and Z'"
          />
        </Box>
        <Select
          label="Model Type"
          options={[{ label: 'Gamma Ray', id: 1 }, { label: 'Interpreted Lithology', id: 2 }, { label: 'Porosity', id: 3 }]}
          onSelect={({ label }) => onChange('modelType', label)}
        />
      </Box>
      <Box display="flex" justifyContent="end" mt="4rem">
        <Button variant="link" mr="1rem" onClick={handleClose}>Cancel</Button>
        <Button
          variant="primary"
          onClick={handleClick}
          disabled={disableSubmit() || isLoading}
        >
          {renderTextButton()}
        </Button>
      </Box>
    </Content>
  )
}

export default AddModel