import React, { useState, useEffect } from 'react';

import {
  Button,
  Steps
} from 'antd';
import classes from 'classnames';
import Modal from 'react-modal';

import removeIcon from '../../assets/close.svg';
import { modalStyleDataSource } from '../../utils/index';
import TableSelectWell from './TableSelectWell';

const { Step } = Steps;

const Title = ({ title, className, classTitle }) => (
  <h3 className={classes(
    'title-row',
    {
      [className]: !!className,
      [classTitle]: !!classTitle
    }
  )}
  >
    {title}
  </h3>
);

Title.defaultProps = {
  classTitle: 'modal-scale__title'
};

const SelectModalInWell = ({
  isOpen, changeInWellModal, onCloseModal,
  onCloseSteps, nextAction, calculating, loading
}) => {
  const [selectedWells, setSelectedWell] = useState(undefined);

  const onSelectWell = (selectedRows) => {
    setSelectedWell(selectedRows);
  };

  useEffect(() => {
    return () => {
      onSelectWell(undefined);
    }; 
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        style={{
          ...modalStyleDataSource,
          overlay: {
            zIndex: 600,
            backgroundColor: 'rgba(0, 0, 0, 0.65)'
          }
        }}
        onRequestClose={() => {
          setSelectedWell(undefined);
          onCloseModal();
        }}
      >
        <div style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
        <img
          onClick={onCloseModal}
          src={removeIcon}
          className="modal-scale__close"
          data-cy="close-modal"
          alt="close modal"
        />
      </div>
        <div style={{ padding: 20 }}>
          <Steps current={0} className="selectModal-inWell-steps" style={{ justifyContent: 'center' }}>
            <Step title="Select Well" />
            <Step title="Well Phases"/>
            <Step title="Match Curves" />
          </Steps>
          <div style={{ marginTop: 30 }}>
            <TableSelectWell onSelectWell={onSelectWell} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40 }}>
            <Button 
              style={{ background: '#333', color: '#fff', width: 100, height: 40 }}
              onClick={onCloseSteps}
            >
              Cancel
            </Button>
            <div style={{ width: 240, display: 'flex', justifyContent: 'space-between' }}>
              <Button 
                style={{ background: '#333', color: '#fff', width: 100, height: 40 }}
                onClick={onCloseSteps}
              >
                Previous
              </Button>
              <div style={{ width: 240, display: 'flex', justifyContent: 'space-between', marginLeft: 10 }}>
                <Button
                  loading={calculating}
                  disabled={!selectedWells}
                  type="primary"
                  style={{ width: 100, height: 40 }}
                  onClick={() => nextAction(selectedWells)}
                  data-cy="next-button"
                >
                  Next
                </Button>
              </div>
              {/* <Button
                loading={calculating}
                disabled={selectedWells === undefined || loading}
                type="primary"
                style={{ maxWidth: loading ? 130 : 100, height: 40 }}
                onClick={calculating ? () => {} : () => nextAction(selectedWells)}
              >
               {loading ? 'Connecting...' : 'Connect'}
              </Button> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectModalInWell;

{/* <Modal
isOpen={isOpen}
style={modalStyleInWell}
onRequestClose={changeInWellModal}
>
<div style={{ padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
  <img
    onClick={changeInWellModal}
    src={removeIcon}
    className="modal-scale__close"
    data-cy="close-modal"
    alt="close modal"
  />
</div>
<div
  className="modal-scale__form"
>
  <div style={{ display: 'flex', justifyContent: 'center' }}>
      <h2 style={{ color: '#fff', fontSize: 20 }}>Select Data Source</h2>   
  </div>
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 45 }}>
    <div onClick={uploadFile} style={{ display: 'flex', cursor: 'pointer', flexDirection: 'column', alignItems: 'center', width: 220 }}>
      <img src={inWellFile} width="50" height="50" />
      <span>Import File</span>
    </div>
    <div onClick={uploadFile} style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center', width: 220 }}>
      <img src={cloudinWell} width="50" height="50" />
      <span>InWell Connection</span>
    </div>
  </div>
  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 90 }}>
    <Button style={{ background: '#333', color: '#fff', width: 120, height: 40 }}>Cancel</Button>
    <Button type="primary" style={{ width: 120, height: 40 }}>Next</Button>
  </div>
  {/* {typeOfTitle && (
    <ScalesConfig
      typeOfTitle={typeOfTitle}
      onClose={closeModal}
    />
  )} 
</div>
</Modal> */}
