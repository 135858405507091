import React, {
    useEffect,
    useState
} from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    Button,
    Collapse,
    Select,
    Popconfirm
} from 'antd';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import Box from '../../Box';
import { StatusBadgeGray, StatusBadgeGreen, StatusBadgeRed } from '../../../Models/Register/Styles';

import Loading from '../Loading';
import SettingsForm from './SettingsForm';
import { getProductionVersion, getDateCreated } from './getInfo';
import { ModelsProvider, useModelsContext } from '../../../hooks/useModels';

const { Panel } = Collapse;



const ListModels = ({
    openModalWellPhases, closeModalWellPhases, onChange, currentPanel,
    parsedModels, modelName, loadingOneModel, data, loading, onClose, setLoading
}) => {
    const models = useSelector(state => state.models) || {};
    const { curveMapping } = useModelsContext();
    const [successModel, setSuccessModel] = useState(false);
    const [showMore, setShowMore] = useState(3);
    const [driftMessage, setDriftMessage] = useState('');

    const displaySuccessModel = () => {
        setSuccessModel(true);
    };
    const displayDriftMessage = (msg) => {
        setDriftMessage(msg);
    };

    let metrics = models.metrics || {};
    if (!Object.keys(metrics).length) {
        const versions = data.items && data.items[modelName] && data.items[modelName].versions;
        if (versions && versions.length) {
            const productionVersion = getProductionVersion(versions)
        }
    }
    const dateCreated = data.items && data.items[modelName] && data.items[modelName].created_at;


    const formatDateCreated = dateCreated && format(dateCreated, "DD-MM-YYYY HH:mm");
    const tags = data.items && data.items[modelName] && data.items[modelName].tags;
    const description = data.items && data.items[modelName] && data.items[modelName].description;
    const inputSchema = Object.keys(curveMapping);
    const inputCurves = data.items && data.items[modelName] && data.items[modelName].schema_definition.input_curves || {};
    const modelUid = data.items && data.items[modelName] && data.items[modelName].uid;
    const getStatus = (model) => data?.items[model]?.status;
    const getHistory = (model) => data?.items[model]?.model_history?.well_info_list || [];

    return (
        <div className='models' >
            <Collapse
                defaultActiveKey={['1']} onChange={onChange}
                expandIconPosition="right"
                forceRender={true}
                accordion
            >
                {parsedModels.map((item) => {
                    const status = getStatus(item);
                    const history = getHistory(item);
                    const metricsData = data?.items[item]?.metrics
                    return (
                        <Panel forceRender={true} key={item} header={
                            <div /*onClick={() => onClick(item)}*/ style={{ display: 'flex' }}>
                                <b>{item}</b>
                                <Box p="0 0.5rem">
                                    {status === 'Completed' &&
                                        <StatusBadgeGreen>{status}</StatusBadgeGreen>}
                                    {status === 'Failed' &&
                                        <StatusBadgeRed>{status}</StatusBadgeRed>}
                                    {status === 'Training' &&
                                        <StatusBadgeGray>{status}</StatusBadgeGray>}
                                </Box>
                            </div>
                        } disabled={status === 'Training' ? true : false}>
                            <div>
                                <div className="container-card-model">
                                    <div className='box-card-model'>
                                        <div className="wrapper-card-model">
                                            {loadingOneModel ? (
                                                <Loading customStyles={{ height: 200 }} />
                                            ) : (
                                                <>
                                                    <ItemModel title="Description" description={description || '-'} />
                                                    <ItemModel title="Metrics" />
                                                    {Object.keys(metricsData).map((metric) =>
                                                        <ItemModel size="sm" key={metric} title={metric.toUpperCase()} description={metricsData[metric].toFixed(4) || '-'} />)}
                                                    <ItemModel title="Date created" description={formatDateCreated || '-'} />
                                                    <h3 className="detail-model-title">Model History</h3>
                                                    <div style={{ display: 'flex', margin: 0, flexWrap: 'wrap'}}>
                                                        {history?.length && history.slice(0, showMore).map((well) =>
                                                            <ItemModelHistory key={well.uid} title={well.name} startDepth={well.start_depth} endDepth={well.end_depth} />)}
                                                    </div>
                                                    {history.length > 3 ? <ShowMoreButton totalLength={history.length} showMore={showMore} setShowMore={setShowMore} /> : null}
                                                    {/*<ItemModel title="Date Registered" description={startEndTime[0] && startEndTime[0].start_time || '-'} /> */}
                                                </>
                                            )
                                            }
                                        </div>
                                    </div>
                                    <div className='box-card-model'>
                                        {loading ? (
                                            <Loading customStyles={{ width: 330, height: 200 }} />
                                        ) : null}
                                        {successModel && !loading ?
                                            <div className="wrapper-message-results">
                                                <div className="text-model-msg-success">
                                                    <h2 className="model-result-msg-success">Completed processing.</h2>
                                                    <h3 className="model-result-msg-applied">See the results by clicking below.</h3>
                                                </div>
                                                <Button className="btn-see-results" onClick={onClose}>
                                                    See Results
                                                </Button>
                                                <p className="model-result-msg-drift">{driftMessage}</p>
                                            </div>
                                            :
                                            <div className="wrapper-card-settings">
                                                {inputCurves && !loading ?
                                                    (<>
                                                        <h2 className="title-model-settings" style={{ marginLeft: 0 }}>Model Schema</h2>
                                                        <SettingsForm
                                                            curveMapping={curveMapping}
                                                            openModalWellPhases={openModalWellPhases}
                                                            modelName={modelName}
                                                            tags={tags}
                                                            clear={currentPanel}
                                                            displaySuccessModel={displaySuccessModel}
                                                            displayDriftMessage={displayDriftMessage}
                                                            loading={loading}
                                                            setLoading={setLoading}
                                                            inputCurves={inputCurves}
                                                            modelUid={modelUid}
                                                            status={status}
                                                        />
                                                    </>) : null}
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </Panel>)
                })}

            </Collapse>
        </div>
    );
};


const ItemModel = ({ title, description, size }) => (
    <div className="box-model-text">
        <h3 className="detail-model-title" style={size == 'sm' ? { fontSize: 14 } : null}>{title}</h3>
        <span className="detail-model-description">{description}</span>
    </div>
);

const BlackTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: 'rgba(0, 0, 0, 0.87)',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        color: '#f5f5f9',
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
    },
}));


const ItemModelHistory = ({ title, startDepth, endDepth }) => (
    <>
        <BlackTooltip
            title={<>
                <span style={{ fontSize: '14px' }}>
                    Start Depth: {startDepth} m
                    <br></br>
                    End Depth: {endDepth} m
                </span>
            </>}
        >
            <div style={{
                display: 'flex',
                backgroundColor: '#333',
                padding: '5px 10px',
                margin: '5px',
                borderRadius: 5
            }} >
                <h3 style={{ margin: 0, whiteSpace: 'nowrap' }}>{title}</h3>
            </div>
        </BlackTooltip>
    </>
);

const ShowMoreButton = ({totalLength, showMore, setShowMore, ...props}) =>{
    const [textButton, setTextButton] = useState('See more');
    const showMoreOrLess = () => {
        if (showMore >= totalLength){
            setTextButton('See less');
        }
    };

    const showMoreHistory = () => {
        if(textButton === 'See less'){
            setShowMore(3);
            setTextButton('See more');
            return;
        };
        setShowMore(prevState => prevState + 3);
    };

    useEffect(() => {
        if(showMore) showMoreOrLess();
    },[showMore]);

    return(
        <Button className='btn-see-more' type='link' style={{ margin: '5px 0' }} onClick={showMoreHistory}{ ...props}>{textButton}</Button>
    )
};

export default ListModels;