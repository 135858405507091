import React from 'react'

import { List, ListItem, Wrapper, Scrolling } from './styles'

const Options = ({ data, isOpen, onSelect }) => {
	if (!isOpen) return null

	return (
		<Wrapper>
			<Scrolling>
				<List>
					{data.map(({ id, label }) => (
						<ListItem key={id} onClick={() => onSelect(id)} data-cy='select-option'>{label}</ListItem>
					))}
				</List>
			</Scrolling>
		</Wrapper>
	)
}

export default Options
