import axios from 'axios';
import store from 'store';
import { getGateway } from './gateway';
import mockModelsData from './getModels.json'

const GATEWAY = getGateway();
const URL = `${GATEWAY}/mlflow-metadata`;
const stage = 'Production'; // Staging Production
const ML_SERVING = `${GATEWAY}/mlflow-serving`;
const ML_REGISTRY = `${GATEWAY}/mlflow-registry`;
const MLAAS_URL = `https://mlaasv2-oofp55ubjq-uc.a.run.app`

const token = store.get('token');

export const fetchModels = async () => {
  
  const resource = `${MLAAS_URL}/mlaas/v1/models`;
  const response = await axios.get(
    resource,
    {
      headers: {
        'access-token': store.get('token'),
        Authorization: `Bearer ${store.get('token')}`,
        'Content-type': 'application/json'
      }
    }
  );
  return response;
};

export const fetchOneModel = async (name) => {
  const resource = `${URL}/models/registered/${name}/${stage}/info`;
  const response = await axios.get(
    resource,
    {
      headers: {
        'access-token': store.get('token'),
        'Content-type': 'application/json'
      }
    }
  );
  return response;
};

export const predict = async (modelUid, data) => {
  const resource = `${MLAAS_URL}/mlaas/v1/models/${modelUid}/predictions`;
  const response = await axios.post(
    resource,
    data ,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json'
      }
    }
  );
  return response;
};

export const predictLithologyScores = async (modelName, data) => {
  const resource = `${ML_SERVING}/models/registered/${modelName}/${stage}/predict-probabilities`;
  const response = await axios.post(
    resource,
    { data },
    {
      headers: {
        'access-token': store.get('token'),
        'Content-type': 'application/json'
      }
    }
  );
  return response;
};

export const fetchRunOneMetadata = async (runId) => {
  const resource = `${URL}/runs/${runId}/info`;
  const response = await axios.get(
    resource,
    {
      headers: {
        'access-token': store.get('token'),
        'Content-type': 'application/json'
      }
    }
  );
  return response;
};

export const deleteModel = async (modelUid) => {
  const resource = `${MLAAS_URL}/mlaas/v1/models/${modelUid}`;
  const response = await axios.delete(
    resource,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json'
      }
    }
  );
  return response;
}

export const addModel = async (body) => {
  const resource = `${ML_REGISTRY}/registry/register`;
  const response = await axios.post(
      resource,
      body,
      {
          headers: {
              'access-token': store.get('token'),
              'Content-type': 'application/json'
          }
      }
  );
  return response;
}