import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from './store';
import { createBrowserHistory } from 'history';
import Modal from 'react-modal';
import SpinnerLogo from 'components/SpinnerLogo';

import {
  useNavigate,
  createBrowserRouter,
} from 'react-router-dom';

import store from 'store';

import { ThemeProvider } from 'styled-components';
import Home from './screens/Home';
import Crossplots from './Crossplots';
import RelativeEthene from './components/RelativeEthene';
import { StylesProvider } from '@material-ui/core/styles';
import Root from './screens/Root';
import {
  Login,
} from '@geowellex/shared-login';
import {
  ProvideAuth,
  useProvideAuth,
} from '@geowellex/shared-login/src/hooks/useAuth';
import { 
  ServiceProvider, 
  useService
} from '@geowellex/shared-login/src/hooks/useService';

import Models from './Models/Train';

import theme from './theme'
import Hydraulics from './new/Hydraulics';
import RegisterCard from './Models/Register/ExperimentCard';
import { MachineLearningProvider } from './hooks/useML';
import { RealtimeProvider } from '@geowellex/shared-hooks';

import { listWell } from './actions/wells';
import { parseWellDataIntegration, updateWellData } from './api/import-data';
import { WELLEX_DATA_TEMP_URL, fetchInWellWatcherSite } from './api/wellwatcher';
import { errorNotification, successNotification } from './utils';
import { error } from 'highcharts';

const history = createBrowserHistory();

const storeReducer = configureStore();


Modal.setAppElement('#root');

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { service, setService } = useService();
  const { checkValidSession, checkingToken, isValidToken } = useProvideAuth();

  useEffect(() => {
    checkValidSession();
    setService('AUTOMUD');
  }, []);


  if (checkingToken) {
    return <SpinnerLogo />;
  }

  if (!isValidToken && isValidToken !== undefined && !checkingToken ) {
    navigate('/login');
  }

  if(!store.get('services_allowed').includes(service)){
    navigate('/login');  
  }

  return <div>{children}</div>;
};

const RootRouter = createBrowserRouter([
  {
    path: "/",
    element:
      <ServiceProvider>
        <Provider store={storeReducer}>
          <ThemeProvider theme={theme}>
              <ProvideAuth>
                <Root />
              </ProvideAuth>
          </ThemeProvider>
        </Provider>
      </ServiceProvider>
  },
  {
    path: "/login",
    element:
      <ServiceProvider>
        <Provider store={storeReducer}>
          <ThemeProvider theme={theme}>
              <ProvideAuth>
                <Root />
              </ProvideAuth>
          </ThemeProvider>
        </Provider>
      </ServiceProvider>
  },
  {
    path: "/home",
    element:
      <ServiceProvider>
        <PrivateRoute>
          <ThemeProvider theme={theme}>
            <StylesProvider injectFirst>
              <Provider store={storeReducer} history={history}>
                  <ProvideAuth>
                    <RealtimeProvider
                      listWell={listWell}
                      parseWellDataIntegration={parseWellDataIntegration}
                      updateWellData={updateWellData}
                      fetchInWellWatcherSite={fetchInWellWatcherSite}
                      afterSuccess={successNotification}
                      afterError={errorNotification}
                      WELLEX_DATA_TEMP_URL={WELLEX_DATA_TEMP_URL}
                    >
                      <MachineLearningProvider>
                        <Home />
                      </MachineLearningProvider>
                    </RealtimeProvider>
                  </ProvideAuth>
              </Provider>
            </StylesProvider>
          </ThemeProvider>
        </PrivateRoute>,
      </ServiceProvider>
  },
  {
    path: "/crossplots/:id",
    element:
      <ServiceProvider>
        <PrivateRoute>
          <StylesProvider injectFirst>
            <Provider store={storeReducer} history={history}>
                <ProvideAuth>
                  <Crossplots />
                </ProvideAuth>
            </Provider>
          </StylesProvider>
        </PrivateRoute>,
      </ServiceProvider>
  },
  {
    path: "/relative-ethene/:id",
    element:
      <ServiceProvider>
        <PrivateRoute>
          <StylesProvider injectFirst>
            <Provider store={storeReducer} history={history}>
                <ProvideAuth>
                  <RelativeEthene />
                </ProvideAuth>
            </Provider>
          </StylesProvider>
        </PrivateRoute>,
      </ServiceProvider>
  },
  {
    path: "/hydraulics",
    element:
      <ServiceProvider>
        <PrivateRoute>
          <StylesProvider injectFirst>
            <Provider store={storeReducer} history={history}>
                <ProvideAuth>
                  <Hydraulics />
                </ProvideAuth>
            </Provider>
          </StylesProvider>
        </PrivateRoute>,
      </ServiceProvider>
  },
]);

export default RootRouter;
